.craeteUser {
  padding: 40px 0px;
}

.resetPassword {
  padding: 90px 0px;
  margin-bottom: -40px;
}

.FormBlock {
  max-width: 450px;
  margin: 0 auto;
}

.FormSection {
  padding: 15px 50px;
}

.SignupTitle {
  text-align: center;
  margin-bottom: 20px;
}

.SignupTitle img {
  width: 100%;
  max-width: 100px;
}

.userHeading span {
  margin-top: 13px;
  margin-left: 9px;
  color: red;
  font-size: 22px;
}

.userHeading {
  display: flex;
  justify-content: center;
}

.userHeading h2 {
  font-size: 22px;
  font-weight: 500;
  text-transform: uppercase;
  color: #fff;
  margin-top: 20px;
  margin-bottom: 10px;
}

.SignupTitle p {
  letter-spacing: 0.6px;
  color: #fafafa;
  line-height: 24px;
  font-size: 15px;
  font-weight: 300;
}

.FormGroup {
  margin-bottom: 10px;
}

.FormSection .FormGroup .FormInput,
.FormSection .FormGroup select {
  border: 1px solid #333;
  padding: 0px 10px;
  height: 40px;
  font-size: 15px;
  width: 100%;
  border-radius: 4px;
  background: #333;
  margin-top: 40px;
}

.FormSection .FormGroup .FormInput::placeholder {
  font-weight: 300;
  color: #f0f0f0;
}

.FormSection .FormGroup .FormInput,
.FormSection .FormGroup ::placeholder {
  color: aliceblue !important;
}

.FormSection .FormGroup .FormInput:focus {
  outline: none !important;
  border: 1px solid #333 !important;
  box-shadow: none !important;
}

.signUpBtn {
  margin-bottom: 15px;
  margin-top: 32px;
}

.imageTitle h2 {
  font-size: 22px;
  font-weight: 500;
  text-transform: uppercase;
  color: #fff;
  line-height: 40px;
  margin-top: 20px;
  margin-bottom: 10px;
}

.imageTitle p {
  font-size: 15px;
  font-weight: 300;
  letter-spacing: 0.6px;
  color: #333;
  color: #fafafa;
  line-height: 24px;
}

.imageTitle {
  text-align: center;
  margin-bottom: 20px;
  margin-top: 40px;
  padding-top: 24px;
  border-top: 1px solid #3e3e3e;
}

.signUpBtn button {
  width: 100%;
  height: 40px;
  font-size: 15px;
  font-weight: 400;
  color: #fff;
  background: #333;
  border: 1px solid #333 !important;
  border-radius: 100px;
}

.AccountSection {
  margin-top: 20px !important;
}

.AccountSection p {
  text-align: center;
  margin-bottom: 0;
  font-size: 15px;
  color: #9c9c9c;
  font-weight: 800;
}

.AccountSection p a {
  color: #111;
  font-weight: 800;
  font-size: 16px;
  text-decoration: none;
}

.GetApp {
  text-align: center;
  margin-top: 20px;
}

.GetApp p {
  margin-bottom: 20px;
  font-size: 15px;
  letter-spacing: 0.6px;
  color: #9c9c9c;
  line-height: 24px;
  font-weight: bold;
}

.AppLink a img {
  width: 100%;
  max-width: 160px;
}

.signUpBtn a {
  font-size: 15px;
  font-weight: 400;
  text-align: center;
  color: #fff !important;
  text-decoration: none;
  display: flex;
  justify-content: center;
  padding-top: 8px;
}

.signUpBtn {
  background-color: #000;
  width: 100%;
  height: 40px;
  border-radius: 100px;
}

@media only screen and (max-width: 400px) {
  .FormSection {
    padding: 0px 8px;
  }
}

@media only screen and (max-width: 479px) {
  .userHeading h2 {
    font-size: 18px !important;
  }

  .imageTitle h2 {
    font-size: 18px;
  }

  .SignupTitle p {
    font-size: 15px;
    padding: 0 !important;
    font-weight: 400 !important;
    color: #828282 !important;
  }

  .imageTitle p {
    font-size: 15px;
    padding: 0 !important;
    font-weight: 400 !important;
    color: #828282 !important;
  }
}

.subborder {
  border-top: 1px solid #fff4f445;
  margin-left: 8px;
  margin-right: 8px;
}

.subTerms {
  padding-top: 18px;
}

.subTerms h2 {
  font-size: 30px;
  font-weight: 800;
  color: #fff;
  text-transform: uppercase;
}

.subTerms p {
  color: #87888a;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.8;
  text-align: left;
  margin: 0px 10px 0px 10px;
}

.subBtn {
  text-align: center;
  /* padding-bottom: 25px; */

}

.subBtn button {
  text-decoration: none;
  color: #fff !important;
  border-color: #fff;
  border-radius: 150px;
  border: 1px solid #f2f2f28f;
  padding: 11px 17px;
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  background-color: initial;
}

.subBtn button:hover {
  color: #ec3642 !important;
  background: #fff !important;
  border-color: #fff;
}

.subscriptionOffer h2 {
  display: flex;
  justify-content: center;
  color: #fff;
  font-size: 30px;
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: 0.6px;
}

.subscriptionOffer p {
  color: #c5c6c9;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.8;
  text-align: left;
  margin-bottom: 0px;
}

.amountSection3 {
  padding-top: 15px;
}

.subRow {
  margin-top: 20px;
}

.subscriptionbox {
  border-radius: 20px;
  width: 100%;
  height: 100%;
  padding: 25px 0px 25px 0px;
}

.timeSection {
  color: #fff;
  text-align: center;
  align-items: center;
  font-size: 32px;
  font-weight: 800;
  text-transform: capitalize;
  line-height: 48px;
  margin-bottom: 10px;
  height: 100px;
  padding: 0px 27px 0px 27px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.subText p {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 0px;
  padding: 10px 47px 0px 47px;
  line-height: 23px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  height: 125px;
}

.amountSection p {
  color: #fff;
  font-size: 30px;
  font-weight: 800;
  text-align: center;
  margin-bottom: 0px;
}

.amountSection {
  margin: 5px 0px;
}

.storyTittle h2 {
  color: #fff;
  font-size: 30px;
  font-weight: 800;
  text-align: center;
  margin-bottom: 20px;
  margin-top: 20px;
}

/* responsive css for subscription card */
@media only screen and (max-width: 325px) {
  .subscriptionOffer h2 {
    font-size: 22px;
    margin-top: 6px !important;
  }
  .subRow {
    margin-top: 25px;
    width: 100%;
    max-width: 155px;
    padding-left: 5px;
    padding-right: 5px;
  }
  .timeSection {
    font-size: 20px;
    line-height: 28px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    padding: 0px 10px 0px 10px;
    height: 100%;
    -webkit-box-orient: vertical;
    min-height: 55px;
  }
  .amountSection {
    margin: 5px 0px;
  }

  .amountSection p {
    font-size: 25px;
    margin-bottom: 0px;
  }
  .subscriptionbox {
    width: 100%;
    min-width: 144px;
    height: unset !important;
    padding: 10px 0px 15px 0px;
  }
  .subText p {
    font-size: 14px;
    padding: 7px 10px 0px 10px;
    line-height: 23px;
    height: 97px;
  }
  .subBtn button {
    padding: 8px 10px;
    font-size: 14px;
  }
  .storyTittle h2 {
    font-size: 19px;
    padding: 0px 5px;
  }
  .subscriptionOffer h2 {
    font-size: 22px;
    margin-top: 6px !important;
  }
  .subTerms h2 {
    font-size: 19px;
  }
  .subscriptionInner {
    padding-left: 5px;
    padding-right: 5px;
  }

  .subscriptionInners {
    padding-left: 5px;
    padding-right: 5px;
  }
  .subscriptionOffer p {
    /* margin-bottom: 0px; */
    margin-bottom: -5px;
  }
}
@media only screen and (min-width: 326px) and (max-width: 330px) {
  .subscriptionOffer h2 {
    font-size: 22px;
    margin-top: 6px !important;
  }
  .subRow {
    margin-top: 25px;
    width: 100%;
    max-width: 158px;
    padding-left: 5px;
    padding-right: 5px;
  }
  .timeSection {
    font-size: 20px;
    line-height: 28px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    padding: 0px 10px 0px 10px;
    height: 100%;
    -webkit-box-orient: vertical;
    min-height: 55px;
  }
  .amountSection {
    margin: 5px 0px;
  }

  .amountSection p {
    font-size: 25px;
    margin-bottom: 0px;
  }
  .subscriptionbox {
    width: 100%;
    min-width: 144px;
    height: unset !important;
    padding: 10px 0px 15px 0px;
  }
  .subText p {
    font-size: 14px;
    padding: 7px 10px 0px 10px;
    line-height: 23px;
    height: 97px;
  }
  .subBtn button {
    padding: 8px 10px;
    font-size: 14px;
  }
  .storyTittle h2 {
    font-size: 19px;
    padding: 0px 5px;
  }
  .subscriptionOffer h2 {
    font-size: 22px;
    margin-top: 6px !important;
  }
  .subTerms h2 {
    font-size: 19px;
  }
  .subscriptionInner {
    padding-left: 5px;
    padding-right: 5px;
  }

  .subscriptionInners {
    padding-left: 5px;
    padding-right: 5px;
  }
  .subscriptionOffer p {
    /* margin-bottom: 0px; */
    margin-bottom: -5px;
  }
}
@media only screen and (min-width: 331px) and (max-width: 335px) {
  .subscriptionOffer h2 {
    font-size: 22px;
    margin-top: 6px !important;
  }
  .subRow {
    margin-top: 25px;
    width: 100%;
    max-width: 160px;
    padding-left: 5px;
    padding-right: 5px;
  }
  .timeSection {
    font-size: 20px;
    line-height: 28px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    padding: 0px 10px 0px 10px;
    height: 100%;
    -webkit-box-orient: vertical;
    min-height: 55px;
  }
  .amountSection {
    margin: 5px 0px;
  }

  .amountSection p {
    font-size: 25px;
    margin-bottom: 0px;
  }
  .subscriptionbox {
    width: 100%;
    min-width: 144px;
    height: unset !important;
    padding: 10px 0px 15px 0px;
  }
  .subText p {
    font-size: 14px;
    padding: 7px 10px 0px 10px;
    line-height: 23px;
    height: 97px;
  }
  .subBtn button {
    padding: 8px 10px;
    font-size: 14px;
  }
  .storyTittle h2 {
    font-size: 19px;
    padding: 0px 5px;
  }
  .subscriptionOffer h2 {
    font-size: 22px;
    margin-top: 6px !important;
  }
  .subTerms h2 {
    font-size: 19px;
  }
  .subscriptionInner {
    padding-left: 5px;
    padding-right: 5px;
  }

  .subscriptionInners {
    padding-left: 5px;
    padding-right: 5px;
  }
  .subscriptionOffer p {
    /* margin-bottom: 0px; */
    margin-bottom: -5px;
  }
}
@media only screen and (min-width: 336px) and (max-width: 340px) {
  .subscriptionOffer h2 {
    font-size: 22px;
    margin-top: 6px !important;
  }
  .subRow {
    margin-top: 25px;
    width: 100%;
    max-width: 163px;
    padding-left: 5px;
    padding-right: 5px;
  }
  .timeSection {
    font-size: 20px;
    line-height: 28px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    padding: 0px 10px 0px 10px;
    height: 100%;
    -webkit-box-orient: vertical;
    min-height: 55px;
  }
  .amountSection {
    margin: 5px 0px;
  }

  .amountSection p {
    font-size: 25px;
    margin-bottom: 0px;
  }
  .subscriptionbox {
    width: 100%;
    min-width: 144px;
    height: unset !important;
    padding: 10px 0px 15px 0px;
  }
  .subText p {
    font-size: 14px;
    padding: 7px 10px 0px 10px;
    line-height: 23px;
    height: 97px;
  }
  .subBtn button {
    padding: 8px 10px;
    font-size: 14px;
  }
  .storyTittle h2 {
    font-size: 19px;
    padding: 0px 5px;
  }
  .subscriptionOffer h2 {
    font-size: 22px;
    margin-top: 6px !important;
  }
  .subTerms h2 {
    font-size: 19px;
  }
  .subscriptionInner {
    padding-left: 5px;
    padding-right: 5px;
  }

  .subscriptionInners {
    padding-left: 5px;
    padding-right: 5px;
  }
  .subscriptionOffer p {
    /* margin-bottom: 0px; */
    margin-bottom: -5px;
  }
}

@media only screen and (min-width: 341px) and (max-width: 345px) {
  .subRow {
    width: 100%;
    max-width: 165px;
    padding-left: 5px;
    padding-right: 5px;
  }
  .subscriptionInner {
    padding-left: 5px;
    padding-right: 5px;
  }

  .subscriptionInners {
    padding-left: 5px;
    padding-right: 5px;
  }

  .subscriptionOffer p {
    /* margin-bottom: 0px; */
    margin-bottom: -5px;
  }
  .timeSection {
    font-size: 23px;
    line-height: 32px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    padding: 0px 4px 0px 4px;
    height: 100%;
    -webkit-box-orient: vertical;
    min-height: 65px;
  }
  .amountSection {
    margin: 5px 0px;
  }
  .amountSection p {
    font-size: 25px;
    margin-bottom: 0px;
  }
  .subscriptionbox {
    width: 100%;
    min-width: 144px;
    height: unset !important;
    padding: 10px 0px 15px 0px;
  }
  .subText p {
    font-size: 14px;
    padding: 7px 8px 0px 8px;
    line-height: 23px;
    height: 104px;
    -webkit-line-clamp: 4;
  }
  .subBtn button {
    padding: 10px 12px;
    font-size: 14px;
  }
  .storyTittle h2 {
    font-size: 19px;
  }
  .subscriptionOffer h2 {
    font-size: 22px;
    margin-top: 6px !important;
  }
  .subTerms h2 {
    font-size: 19px;
  }
  /* .subscriptionInner{
    padding-left: 2px;
    padding-right: 2px;
  } */
}

@media only screen and (min-width: 346px) and (max-width: 352px) {
  .subRow {
    width: 100%;
    max-width: 168px;
    padding-left: 5px;
    padding-right: 5px;
  }
  .subscriptionInner {
    padding-left: 5px;
    padding-right: 5px;
  }

  .subscriptionInners {
    padding-left: 5px;
    padding-right: 5px;
  }

  .subscriptionOffer p {
    /* margin-bottom: 0px; */
    margin-bottom: -5px;
  }
  .timeSection {
    font-size: 23px;
    line-height: 32px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    padding: 0px 5px 0px 5px;
    height: 100%;
    -webkit-box-orient: vertical;
    min-height: 65px;
  }
  .amountSection {
    margin: 5px 0px;
  }
  .amountSection p {
    font-size: 25px;
    margin-bottom: 0px;
  }
  .subscriptionbox {
    width: 100%;
    min-width: 144px;
    height: unset !important;
    padding: 10px 0px 15px 0px;
  }
  .subText p {
    font-size: 14px;
    padding: 7px 8px 0px 8px;
    line-height: 23px;
    height: 104px;
    -webkit-line-clamp: 4;
  }
  .subBtn button {
    padding: 10px 12px;
    font-size: 14px;
  }
  .storyTittle h2 {
    font-size: 19px;
  }
  .subscriptionOffer h2 {
    font-size: 22px;
    margin-top: 6px !important;
  }
  .subTerms h2 {
    font-size: 19px;
  }
}

@media only screen and (min-width: 353px) and (max-width: 359px) {
  .subRow {
    width: 100%;
    max-width: 171px;
    padding-left: 5px;
    padding-right: 5px;
  }
  .subscriptionInner {
    padding-left: 5px;
    padding-right: 5px;
  }

  .subscriptionInners {
    padding-left: 5px;
    padding-right: 5px;
  }

  .subscriptionOffer p {
    /* margin-bottom: 0px; */
    margin-bottom: -5px;
  }
  .timeSection {
    font-size: 23px;
    line-height: 32px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    padding: 0px 5px 0px 5px;
    height: 100%;
    -webkit-box-orient: vertical;
    min-height: 65px;
  }
  .amountSection {
    margin: 5px 0px;
  }
  .amountSection p {
    font-size: 25px;
    margin-bottom: 0px;
  }
  .subscriptionbox {
    width: 100%;
    min-width: 144px;
    height: unset !important;
    padding: 10px 0px 15px 0px;
  }
  .subText p {
    font-size: 14px;
    padding: 7px 8px 0px 8px;
    line-height: 23px;
    height: 104px;
    -webkit-line-clamp: 4;
  }
  .subBtn button {
    padding: 10px 12px;
    font-size: 14px;
  }
  .storyTittle h2 {
    font-size: 19px;
  }
  .subscriptionOffer h2 {
    font-size: 22px;
    margin-top: 6px !important;
  }
  .subTerms h2 {
    font-size: 19px;
  }
}


@media only screen and (min-width: 360px) and (max-width: 365px) {
  .subRow {
    width: 100%;
    max-width: 175px;
    padding-left: 5px;
    padding-right: 5px;
  }
  .subscriptionInner {
    padding-left: 5px;
    padding-right: 5px;
  }
  .subscriptionInners {
    padding-left: 5px;
    padding-right: 5px;
  }
  .subscriptionOffer p {
    /* margin-bottom: 0px; */
    margin-bottom: -5px;
  }
  .timeSection {
    font-size: 20px;
    line-height: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    padding: 0px 18px 0px 18px;
    height: 100%;
    -webkit-box-orient: vertical;
    min-height: 62px;
  }
  .amountSection {
    margin: 5px 0px;
  }
  .amountSection p {
    font-size: 25px;
    margin-bottom: 0px;
  }
  .subscriptionbox {
    width: 100%;
    min-width: 144px;
    height: unset !important;
    padding: 10px 0px 15px 0px;
  }
  .subText p {
    font-size: 14px;
    padding: 7px 10px 0px 10px;
    line-height: 23px;
    height: 97px;
    -webkit-line-clamp: 4;
  }
  .subBtn button {
    padding: 10px 12px;
    font-size: 14px;
  }
  .amountSection {
    margin: 7px 0px;
  }
  .storyTittle h2 {
    font-size: 19px;
  }
  .subscriptionOffer h2 {
    font-size: 22px;
    margin-top: 6px !important;
  }
  .subTerms h2 {
    font-size: 19px;
  }
}
@media only screen and (min-width: 366px) and (max-width: 370px) {
  .subRow {
    width: 100%;
    max-width: 178px;
    padding-left: 5px;
    padding-right: 5px;
  }
  .subscriptionInner {
    padding-left: 5px;
    padding-right: 5px;
  }
  .subscriptionInners {
    padding-left: 5px;
    padding-right: 5px;
  }
  .subscriptionOffer p {
    /* margin-bottom: 0px; */
    margin-bottom: -5px;
  }
  .timeSection {
    font-size: 20px;
    line-height: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    padding: 0px 18px 0px 18px;
    height: 100%;
    -webkit-box-orient: vertical;
    min-height: 62px;
  }
  .amountSection {
    margin: 5px 0px;
  }
  .amountSection p {
    font-size: 25px;
    margin-bottom: 0px;
  }
  .subscriptionbox {
    width: 100%;
    min-width: 144px;
    height: unset !important;
    padding: 10px 0px 15px 0px;
  }
  .subText p {
    font-size: 14px;
    padding: 7px 10px 0px 10px;
    line-height: 23px;
    height: 97px;
    -webkit-line-clamp: 4;
  }
  .subBtn button {
    padding: 10px 12px;
    font-size: 14px;
  }
  .amountSection {
    margin: 7px 0px;
  }
  .storyTittle h2 {
    font-size: 19px;
  }
  .subscriptionOffer h2 {
    font-size: 22px;
    margin-top: 6px !important;
  }
  .subTerms h2 {
    font-size: 19px;
  }
}
@media only screen and (min-width: 371px) and (max-width: 374px) {
  .subRow {
    width: 100%;
    max-width: 180px;
    padding-left: 5px;
    padding-right: 5px;
  }
  .subscriptionInner {
    padding-left: 5px;
    padding-right: 5px;
  }
  .subscriptionInners {
    padding-left: 5px;
    padding-right: 5px;
  }
  .subscriptionOffer p {
    /* margin-bottom: 0px; */
    margin-bottom: -5px;
  }
  .timeSection {
    font-size: 20px;
    line-height: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    padding: 0px 21px 0px 21px;
    height: 100%;
    -webkit-box-orient: vertical;
    min-height: 62px;
  }
  .amountSection {
    margin: 5px 0px;
  }
  .amountSection p {
    font-size: 25px;
    margin-bottom: 0px;
  }
  .subscriptionbox {
    width: 100%;
    min-width: 144px;
    height: unset !important;
    padding: 10px 0px 15px 0px;
  }
  .subText p {
    font-size: 14px;
    padding: 7px 10px 0px 10px;
    line-height: 23px;
    height: 97px;
    -webkit-line-clamp: 4;
  }
  .subBtn button {
    padding: 10px 12px;
    font-size: 14px;
  }
  .amountSection {
    margin: 7px 0px;
  }
  .storyTittle h2 {
    font-size: 19px;
  }
  .subscriptionOffer h2 {
    font-size: 22px;
    margin-top: 6px !important;
  }
  .subTerms h2 {
    font-size: 19px;
  }
}
@media only screen and (min-width: 375px) and (max-width: 380px) {
  .subRow {
    width: 100%;
    max-width: 182px;
    padding-left: 5px;
    padding-right: 5px;
  }

  .subscriptionInner {
    padding-left: 5px;
    padding-right: 5px;
  }

  .subscriptionInners {
    padding-left: 5px;
    padding-right: 5px;
  }
  .subscriptionOffer p {
    margin-bottom: -5px;
  }
  .timeSection {
    font-size: 20px;
    line-height: 28px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    padding: 0px 23px 0px 23px;
    height: 100%;
    -webkit-box-orient: vertical;
    min-height: 58px;
  }
  .amountSection {
    margin: 5px 0px;
  }
  .amountSection p {
    font-size: 25px;
    margin-bottom: 0px;
  }
  .subscriptionbox {
    width: 100%;
    min-width: 144px;
    height: unset !important;
    padding: 10px 0px 15px 0px;
  }
  .subText p {
    font-size: 13px;
    padding: 7px 10px 0px 10px;
    line-height: 23px;
    height: 100px;
    -webkit-line-clamp: 4;
  }
  .subBtn button {
    padding: 10px 12px;
    font-size: 14px;
  }
  .amountSection {
    margin: 7px 0px;
  }
  .storyTittle h2 {
    font-size: 19px;
  }
  .subscriptionOffer h2 {
    font-size: 22px;
    margin-top: 6px !important;
  }
  .subTerms h2 {
    font-size: 19px;
  }
  .subscriptionInner {
    padding-left: 5px;
    padding-right: 5px;
  }
}
@media only screen and (min-width: 381px) and (max-width: 389px) {
  .subRow {
    width: 100%;
    max-width: 185px;
    padding-left: 5px;
    padding-right: 5px;
  }
   .subscriptionInner {
    padding-left: 5px;
    padding-right: 5px;
  }

  .subscriptionInners {
    padding-left: 5px;
    padding-right: 5px;
  }
  .subscriptionOffer p {
    /* margin-bottom: 0px; */
    margin-bottom: -5px;
  }
  .timeSection {
    font-size: 20px;
    line-height: 28px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    padding: 0px 26px 0px 26px;
    height: 100%;
    -webkit-box-orient: vertical;
    min-height: 58px;
  }
  .amountSection {
    margin: 5px 0px;
  }
  .amountSection p {
    font-size: 25px;
    margin-bottom: 0px;
  }
  .subscriptionbox {
    width: 100%;
    min-width: 144px;
    height: unset !important;
    padding: 10px 0px 15px 0px;
  }
  .subText p {
    font-size: 13px;
    padding: 7px 10px 0px 10px;
    line-height: 23px;
    height: 100px;
    -webkit-line-clamp: 4;
  }
  .subBtn button {
    padding: 10px 12px;
    font-size: 14px;
  }
  .amountSection {
    margin: 7px 0px;
  }
  .storyTittle h2 {
    font-size: 19px;
  }
  .subscriptionOffer h2 {
    font-size: 22px;
    margin-top: 6px !important;
  }
  .subTerms h2 {
    font-size: 19px;
  }
  .subscriptionInner {
    padding-left: 5px;
    padding-right: 5px;
  }
}

@media only screen and (min-width: 390px) and (max-width: 399px) {
  .subRow {
    width: 100%;
    max-width: 190px;
    padding-left: 5px;
    padding-right: 5px;
  }
  .subscriptionInner {
    padding-left: 5px;
    padding-right: 5px;
  }
  .subscriptionInners {
    padding-left: 5px;
    padding-right: 5px;
  }
  .subscriptionOffer p {
    /* margin-bottom: 0px; */
    margin-bottom: -5px;
  }
  .timeSection {
    font-size: 24px;
    line-height: 32px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    padding: 0px 13px 0px 13px;
    height: 100%;
    -webkit-box-orient: vertical;
    min-height: 68px;
  }
  .amountSection {
    margin: 5px 0px;
  }
  .amountSection p {
    font-size: 25px;
    margin-bottom: 0px;
  }
  .subscriptionbox {
    width: 100%;
    min-width: 144px;
    height: unset !important;
    padding: 10px 0px 15px 0px;
  }
  .subText p {
    font-size: 14px;
    padding: 7px 10px 0px 10px;
    line-height: 23px;
    height: 97px;
    -webkit-line-clamp: 4;
  }
  .subBtn button {
    padding: 10px 12px;
    font-size: 14px;
  }
  .amountSection {
    margin: 7px 0px;
  }
  .storyTittle h2 {
    font-size: 19px;
  }
  .subscriptionOffer h2 {
    font-size: 22px;
    margin-top: 6px !important;
  }
  .subTerms h2 {
    font-size: 19px;
  }
}
@media only screen and (min-width: 400px) and (max-width: 405px) {
  .subRow {
    width: 100%;
    max-width: 195px;
    padding-left: 5px;
    padding-right: 5px;
  }
  .subscriptionInner {
    padding-left: 5px;
    padding-right: 5px;
  }
  .subscriptionInners {
    padding-left: 5px;
    padding-right: 5px;
  }
  .subscriptionOffer p {
    /* margin-bottom: 0px; */
    margin-bottom: -5px;
  }
  .timeSection {
    font-size: 24px;
    line-height: 32px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    padding: 0px 17px 0px 17px;
    height: 100%;
    -webkit-box-orient: vertical;
    min-height: 68px;
    /* -webkit-box-pack: center; */
  }
  .amountSection {
    margin: 5px 0px;
  }
  .amountSection p {
    font-size: 25px;
    margin-bottom: 0px;
  }
  .subscriptionbox {
    width: 100%;
    min-width: 144px;
    height: unset !important;
    padding: 10px 0px 15px 0px;
  }
  .subText p {
    font-size: 14px;
    padding: 7px 10px 0px 10px;
    line-height: 23px;
    height: 97px;
    -webkit-line-clamp: 4;
  }
  .subBtn button {
    padding: 10px 12px;
    font-size: 14px;
  }
  .amountSection {
    margin: 7px 0px;
  }
  .storyTittle h2 {
    font-size: 19px;
  }
  .subscriptionOffer h2 {
    font-size: 22px;
    margin-top: 6px !important;
  }
  .subTerms h2 {
    font-size: 19px;
  }
}

@media only screen and (min-width: 406px) and (max-width: 410px) {
  .subRow {
    width: 100%;
    max-width: 198px;
    padding-left: 5px;
    padding-right: 5px;
  }
  .subscriptionInner {
    padding-left: 5px;
    padding-right: 5px;
  }
  .subscriptionInners {
    padding-left: 5px;
    padding-right: 5px;
  }
  .subscriptionOffer p {
    /* margin-bottom: 0px; */
    margin-bottom: -5px;
  }
  .timeSection {
    font-size: 25px;
    line-height: 40px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    padding: 0px 16px 0px 16px;
    height: 100%;
    -webkit-box-orient: vertical;
    min-height: 85px;
  }
  .amountSection {
    margin: 5px 0px;
  }
  .amountSection p {
    font-size: 25px;
    margin-bottom: 0px;
  }
  .subscriptionbox {
    width: 100%;
    min-width: 144px;
    height: unset !important;
    padding: 10px 0px 15px 0px;
  }
  .subText p {
    font-size: 14px;
    padding: 7px 10px 0px 10px;
    line-height: 23px;
    height: 97px;
    -webkit-line-clamp: 4;
  }
  .subBtn button {
    padding: 10px 12px;
    font-size: 14px;
  }
  .amountSection {
    margin: 7px 0px;
  }
  .storyTittle h2 {
    font-size: 19px;
  }
  .subscriptionOffer h2 {
    font-size: 22px;
    margin-top: 6px !important;
  }
  .subTerms h2 {
    font-size: 19px;
  }
}
@media only screen and (min-width: 411px) and (max-width: 413px) {
  .subRow {
    width: 100%;
    max-width: 200px;
    padding-left: 5px;
    padding-right: 5px;
  }
  .subscriptionInner {
    padding-left: 5px;
    padding-right: 5px;
  }
  .subscriptionInners {
    padding-left: 5px;
    padding-right: 5px;
  }
  .subscriptionOffer p {
    /* margin-bottom: 0px; */
    margin-bottom: -5px;
  }
  .timeSection {
    font-size: 25px;
    line-height: 40px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    padding: 0px 16px 0px 16px;
    height: 100%;
    -webkit-box-orient: vertical;
    min-height: 85px;
  }
  .amountSection {
    margin: 5px 0px;
  }
  .amountSection p {
    font-size: 25px;
    margin-bottom: 0px;
  }
  .subscriptionbox {
    width: 100%;
    min-width: 144px;
    height: unset !important;
    padding: 10px 0px 15px 0px;
  }
  .subText p {
    font-size: 14px;
    padding: 7px 10px 0px 10px;
    line-height: 23px;
    height: 97px;
    -webkit-line-clamp: 4;
  }
  .subBtn button {
    padding: 10px 12px;
    font-size: 14px;
  }
  .amountSection {
    margin: 7px 0px;
  }
  .storyTittle h2 {
    font-size: 19px;
  }
  .subscriptionOffer h2 {
    font-size: 22px;
    margin-top: 6px !important;
  }
  .subTerms h2 {
    font-size: 19px;
  }
}

@media only screen and (min-width: 414px) and (max-width: 420px) {
  .subRow {
    width: 100%;
    max-width: 202px;
    padding-left: 5px;
    padding-right: 5px;
  }
  .subscriptionInner {
    padding-left: 5px;
    padding-right: 5px;
  }
  .subscriptionInners {
    padding-left: 5px;
    padding-right: 5px;
  }
  .subscriptionOffer p {
    margin-bottom: -5px;
  }
  .timeSection {
    font-size: 28px;
    line-height: 41px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    padding: 0px 6px 0px 6px;
    height: 100%;
    -webkit-box-orient: vertical;
    min-height: 87px;
  }
  .amountSection {
    margin: 5px 0px;
  }
  .amountSection p {
    font-size: 25px;
    margin-bottom: 0px;
  }
  .subscriptionbox {
    width: 100%;
    min-width: 144px;
    height: unset !important;
    padding: 10px 0px 15px 0px;
  }
  .subText p {
    font-size: 14px;
    padding: 7px 10px 0px 10px;
    line-height: 23px;
    height: 97px;
    -webkit-line-clamp: 4;
  }
  .subBtn button {
    padding: 10px 12px;
    font-size: 14px;
  }
  .amountSection {
    margin: 7px 0px;
  }
  .storyTittle h2 {
    font-size: 19px;
  }
  .subscriptionOffer h2 {
    font-size: 22px;
    margin-top: 6px !important;
  }
  .subTerms h2 {
    font-size: 19px;
  }
}
@media only screen and (min-width: 421px) and (max-width: 427px) {
  .subRow {
    width: 100%;
    max-width: 205px;
    padding-left: 5px;
    padding-right: 5px;
  }
  .subscriptionInner {
    padding-left: 5px;
    padding-right: 5px;
   
  }

  .subscriptionInners {
    padding-left: 5px;
    padding-right: 5px;
  }
  .subscriptionOffer p {
    margin-bottom: -5px;
  }
  .timeSection {
    font-size: 28px;
    line-height: 41px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    padding: 0px 7px 0px 7px;
    height: 100%;
    -webkit-box-orient: vertical;
    min-height: 87px;
  }
  .amountSection {
    margin: 5px 0px;
  }
  .amountSection p {
    font-size: 25px;
    margin-bottom: 0px;
  }
  .subscriptionbox {
    width: 100%;
    min-width: 144px;
    height: unset !important;
    padding: 10px 0px 15px 0px;
  }
  .subText p {
    font-size: 14px;
    padding: 7px 10px 0px 10px;
    line-height: 23px;
    height: 97px;
    -webkit-line-clamp: 4;
  }
  .subBtn button {
    padding: 10px 12px;
    font-size: 14px;
  }
  .amountSection {
    margin: 7px 0px;
  }
  .storyTittle h2 {
    font-size: 19px;
  }
  .subscriptionOffer h2 {
    font-size: 22px;
    margin-top: 6px !important;
  }
  .subTerms h2 {
    font-size: 19px;
  }
}
@media only screen and (min-width: 428px) and (max-width: 430px) {
  .subRow {
    width: 100%;
    max-width: 209px;
    padding-left: 5px;
    padding-right: 5px;
  }
  .subscriptionInner {
    padding-left: 5px;
    padding-right: 5px;
   
  }

  .subscriptionInners {
    padding-left: 5px;
    padding-right: 5px;
  }
  .subscriptionOffer p {
    /* margin-bottom: 0px; */
    margin-bottom: -5px;
  }
  .timeSection {
    font-size: 28px;
    line-height: 41px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    padding: 0px 9px 0px 9px;
    height: 100%;
    -webkit-box-orient: vertical;
    min-height: 87px;
  }
  .amountSection {
    margin: 5px 0px;
  }
  .amountSection p {
    font-size: 25px;
    margin-bottom: 0px;
  }
  .subscriptionbox {
    width: 100%;
    min-width: 144px;
    height: unset !important;
    padding: 10px 0px 15px 0px;
  }
  .subText p {
    font-size: 14px;
    padding: 7px 10px 0px 10px;
    line-height: 23px;
    height: 97px;
    -webkit-line-clamp: 4;
  }
  .subBtn button {
    padding: 10px 12px;
    font-size: 14px;
  }
  .amountSection {
    margin: 7px 0px;
  }
  .storyTittle h2 {
    font-size: 19px;
  }
  .subscriptionOffer h2 {
    font-size: 22px;
    margin-top: 6px !important;
  }
  .subTerms h2 {
    font-size: 19px;
  }
}


@media only screen and (min-width: 431px) and (max-width: 436px) {
  .subRow {
    width: 100%;
    max-width: 210px;
    padding-left: 5px;
    padding-right: 5px;
  }
  .subscriptionInner {
    padding-left: 5px;
    padding-right: 5px;
  }
  .subscriptionInners {
    padding-left: 5px;
    padding-right: 5px;
  }
  .subscriptionOffer p {
    /* margin-bottom: 0px; */
    margin-bottom: -5px;
  }

  .timeSection {
    font-size: 28px;
    line-height: 43px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    padding: 0px 10px 0px 10px;
    height: 100%;
    -webkit-box-orient: vertical;
    min-height: 85px;
  }
  .amountSection {
    margin: 5px 0px;
  }
  .amountSection p {
    font-size: 25px;
    margin-bottom: 0px;
  }
  .subscriptionbox {
    width: 100%;
    min-width: 144px;
    height: unset !important;
    padding: 10px 0px 15px 0px;
  }
  .subText p {
    font-size: 14px;
    padding: 7px 10px 0px 10px;
    line-height: 23px;
    height: 97px;
    -webkit-line-clamp: 4;
  }
  .subBtn button {
    padding: 10px 12px;
    font-size: 14px;
  }
  .amountSection {
    margin: 7px 0px;
  }
  .storyTittle h2 {
    font-size: 19px;
  }
  .subscriptionOffer h2 {
    font-size: 22px;
    margin-top: 6px !important;
  }
  .subTerms h2 {
    font-size: 19px;
  }
}
@media only screen and (min-width: 437px) and (max-width: 440px) {
  .subRow {
    width: 100%;
    max-width: 213px;
    padding-left: 5px;
    padding-right: 5px;
  }
  .subscriptionInner {
    padding-left: 5px;
    padding-right: 5px;
  }
  .subscriptionInners {
    padding-left: 5px;
    padding-right: 5px;
  }
  .subscriptionOffer p {
    /* margin-bottom: 0px; */
    margin-bottom: -5px;
  }

  .timeSection {
    font-size: 28px;
    line-height: 43px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    padding: 0px 10px 0px 10px;
    height: 100%;
    -webkit-box-orient: vertical;
    min-height: 85px;
  }
  .amountSection {
    margin: 5px 0px;
  }
  .amountSection p {
    font-size: 25px;
    margin-bottom: 0px;
  }
  .subscriptionbox {
    width: 100%;
    min-width: 144px;
    height: unset !important;
    padding: 10px 0px 15px 0px;
  }
  .subText p {
    font-size: 14px;
    padding: 7px 10px 0px 10px;
    line-height: 23px;
    height: 97px;
    -webkit-line-clamp: 4;
  }
  .subBtn button {
    padding: 10px 12px;
    font-size: 14px;
  }
  .amountSection {
    margin: 7px 0px;
  }
  .storyTittle h2 {
    font-size: 19px;
  }
  .subscriptionOffer h2 {
    font-size: 22px;
    margin-top: 6px !important;
  }
  .subTerms h2 {
    font-size: 19px;
  }
}
@media only screen and (min-width: 441px) and (max-width: 450px) {
  .subRow {
    width: 100%;
    max-width: 214px;
    padding-left: 5px;
    padding-right: 5px;
  }
  .subscriptionInner {
    padding-left: 5px;
    padding-right: 5px;
  }

  .subscriptionInners {
    padding-left: 5px;
    padding-right: 5px;
  }
  
  .subscriptionOffer p {
    /* margin-bottom: 0px; */
    margin-bottom: -5px;
  }

  .timeSection {
    font-size: 28px;
    line-height: 43px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    padding: 0px 12px 0px 12px;
    height: 100%;
    -webkit-box-orient: vertical;
    min-height: 85px;
  }
  .amountSection {
    margin: 5px 0px;
  }
  .amountSection p {
    font-size: 25px;
    margin-bottom: 0px;
  }
  .subscriptionbox {
    width: 100%;
    min-width: 144px;
    height: unset !important;
    padding: 10px 0px 15px 0px;
  }
  .subText p {
    font-size: 14px;
    padding: 7px 10px 0px 10px;
    line-height: 23px;
    height: 97px;
    -webkit-line-clamp: 4;
  }
  .subBtn button {
    padding: 10px 12px;
    font-size: 14px;
  }
  .amountSection {
    margin: 7px 0px;
  }
  .storyTittle h2 {
    font-size: 19px;
  }
  .subscriptionOffer h2 {
    font-size: 22px;
    margin-top: 6px !important;
  }
  .subTerms h2 {
    font-size: 19px;
  }
}
@media only screen and (min-width: 451px) and (max-width: 460px) {
  .subRow {
    width: 100%;
    max-width: 220px;
    padding-left: 5px;
    padding-right: 5px;
  }
  .subscriptionInner {
    padding-left: 5px;
    padding-right: 5px;
  }
  .subscriptionInners {
    padding-left: 5px;
    padding-right: 5px;
  }
  .subscriptionOffer p {
    /* margin-bottom: 0px; */
    margin-bottom: -5px;
  }
  .timeSection {
    font-size: 30px;
    line-height: 41px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    padding: 0px 8px 0px 8px;
    height: 100%;
    -webkit-box-orient: vertical;
    min-height: 80px;
  }

  .amountSection {
    margin: 5px 0px;
  }
  .amountSection p {
    font-size: 25px;
    margin-bottom: 0px;
  }
  .subscriptionbox {
    width: 100%;
    min-width: 144px;
    height: unset !important;
    padding: 10px 0px 15px 0px;
  }
  .subText p {
    font-size: 14px;
    padding: 7px 10px 0px 10px;
    line-height: 23px;
    height: 97px;
    -webkit-line-clamp: 4;
  }
  .subBtn button {
    padding: 10px 12px;
    font-size: 14px;
  }
  .amountSection {
    margin: 7px 0px;
  }
  .storyTittle h2 {
    font-size: 19px;
  }
  .subscriptionOffer h2 {
    font-size: 22px;
    margin-top: 6px !important;
  }
  .subTerms h2 {
    font-size: 19px;
  }
}
@media only screen and (min-width: 461px) and (max-width: 470px) {
  .subRow {
    width: 100%;
    max-width: 224px;
    padding-left: 5px;
    padding-right: 5px;
  }
  .subscriptionInner {
    padding-left: 5px;
    padding-right: 5px;
  }

  .subscriptionInners {
    padding-left: 5px;
    padding-right: 5px;
  }
  .subscriptionOffer p {
    /* margin-bottom: 0px; */
    margin-bottom: -5px;
  }
  .timeSection {
    font-size: 30px;
    line-height: 41px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    padding: 0px 10px 0px 10px;
    height: 100%;
    -webkit-box-orient: vertical;
    min-height: 80px;
  }

  .amountSection {
    margin: 5px 0px;
  }
  .amountSection p {
    font-size: 25px;
    margin-bottom: 0px;
  }
  .subscriptionbox {
    width: 100%;
    min-width: 144px;
    height: unset !important;
    padding: 10px 0px 15px 0px;
  }
  .subText p {
    font-size: 14px;
    padding: 7px 10px 0px 10px;
    line-height: 23px;
    height: 97px;
    -webkit-line-clamp: 4;
  }
  .subBtn button {
    padding: 10px 12px;
    font-size: 14px;
  }
  .amountSection {
    margin: 7px 0px;
  }
  .storyTittle h2 {
    font-size: 19px;
  }
  .subscriptionOffer h2 {
    font-size: 22px;
    margin-top: 6px !important;
  }
  .subTerms h2 {
    font-size: 19px;
  }
}

@media only screen and (min-width: 471px) and (max-width: 479px) {
  .subRow {
    width: 100%;
    max-width: 228px;
    padding-left: 5px;
    padding-right: 5px;
  }
  .subscriptionInner {
    padding-left: 5px;
    padding-right: 5px;
  }
  .subscriptionInners {
    padding-left: 5px;
    padding-right: 5px;
  }
  .subscriptionOffer p {
    /* margin-bottom: 0px; */
    margin-bottom: -5px;
  }

  .timeSection {
    font-size: 30px;
    line-height: 41px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    padding: 0px 13px 0px 13px;
    height: 100%;
    -webkit-box-orient: vertical;
    min-height: 80px;
  }

  .amountSection {
    margin: 5px 0px;
  }
  .amountSection p {
    font-size: 25px;
    margin-bottom: 0px;
  }
  .subscriptionbox {
    width: 100%;
    min-width: 144px;
    height: unset !important;
    padding: 10px 0px 15px 0px;
  }
  .subText p {
    font-size: 14px;
    padding: 7px 10px 0px 10px;
    line-height: 23px;
    height: 97px;
    -webkit-line-clamp: 4;
  }
  .subBtn button {
    padding: 10px 12px;
    font-size: 14px;
  }
  .amountSection {
    margin: 7px 0px;
  }
  .storyTittle h2 {
    font-size: 19px;
  }
  .subscriptionOffer h2 {
    font-size: 22px;
    margin-top: 6px !important;
  }
  .subTerms h2 {
    font-size: 19px;
  }
}
@media only screen and (min-width: 480px) and (max-width: 491px) {
  .subRow {
    width: 100%;
    max-width: 235px;
    padding-left: 5px;
    padding-right: 5px;
  }
  .subscriptionInner {
    padding-left: 5px;
    padding-right: 5px;
  }
  .subscriptionInners {
    padding-left: 5px;
    padding-right: 5px;
  }
  .subscriptionOffer p {
    /* margin-bottom: 0px; */
    margin-bottom: -5px;
  }

  .timeSection {
    font-size: 30px;
    line-height: 41px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    padding: 0px 16px 0px 16px;
    height: 100%;
    -webkit-box-orient: vertical;
    min-height: 80px;
  }

  .amountSection {
    margin: 5px 0px;
  }
  .amountSection p {
    font-size: 25px;
    margin-bottom: 0px;
  }
  .subscriptionbox {
    width: 100%;
    min-width: 144px;
    height: unset !important;
    padding: 10px 0px 15px 0px;
  }
  .subText p {
    font-size: 14px;
    padding: 7px 10px 0px 10px;
    line-height: 23px;
    height: 97px;
    -webkit-line-clamp: 4;
  }
  .subBtn button {
    padding: 10px 12px;
    font-size: 14px;
  }
  .amountSection {
    margin: 7px 0px;
  }
  .storyTittle h2 {
    font-size: 19px;
  }
  .subscriptionOffer h2 {
    font-size: 22px;
    margin-top: 6px !important;
  }
  .subTerms h2 {
    font-size: 19px;
  }
}

@media only screen and (min-width: 492px) and (max-width: 497px) {
  .subRow {
    width: 100%;
    max-width: 241px;
    padding-left: 5px;
    padding-right: 5px;
  }
  .subscriptionInner {
    padding-left: 5px;
    padding-right: 5px;
  }
  .subscriptionInners {
    padding-left: 5px;
    padding-right: 5px;
  }
  .subscriptionOffer p {
    /* margin-bottom: 0px; */
    margin-bottom: -5px;
  }

  .timeSection {
    font-size: 32px;
    line-height: 41px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    padding: 0px 13px 0px 13px;
    height: 100%;
    -webkit-box-orient: vertical;
    min-height: 82px;
  }
  .amountSection {
    margin: 5px 0px;
  }
  .amountSection p {
    font-size: 25px;
    margin-bottom: 0px;
  }
  .subscriptionbox {
    width: 100%;
    min-width: 144px;
    height: unset !important;
    padding: 10px 0px 15px 0px;
  }
  .subText p {
    font-size: 14px;
    padding: 7px 10px 0px 10px;
    line-height: 23px;
    height: 97px;
    -webkit-line-clamp: 4;
  }
  .subBtn button {
    padding: 10px 12px;
    font-size: 14px;
  }
  .amountSection {
    margin: 7px 0px;
  }
  .storyTittle h2 {
    font-size: 19px;
  }
  .subscriptionOffer h2 {
    font-size: 22px;
    margin-top: 6px !important;
  }
  .subTerms h2 {
    font-size: 19px;
  }
}

@media only screen and (min-width: 498px) and (max-width: 500px) {
  .subRow {
    width: 100%;
    max-width: 244px;
    padding-left: 5px;
    padding-right: 5px;
  }
  .subscriptionInner {
    padding-left: 5px;
    padding-right: 5px;
  }
  .subscriptionInners {
    padding-left: 5px;
    padding-right: 5px;
  }
  .subscriptionOffer p {
    /* margin-bottom: 0px; */
    margin-bottom: -5px;
  }
  .timeSection {
    font-size: 32px;
    line-height: 42px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    padding: 0px 14px 0px 14px;
    height: 100%;
    min-height: 80px;
    -webkit-box-orient: vertical;
  }
  .amountSection {
    margin: 5px 0px;
  }
  .amountSection p {
    font-size: 30px;
    margin-bottom: 0px;
  }
  .subscriptionbox {
    width: 100%;
    min-width: 144px;
    height: unset !important;
    padding: 10px 0px 15px 0px;
  }
  .subText p {
    font-size: 14px;
    padding: 7px 12px 0px 12px;
    line-height: 23px;
    height: 125px;
  }
  .subBtn button {
    padding: 10px 12px;
    font-size: 14px;
  }
  .storyTittle h2 {
    font-size: 19px;
  }
  .subscriptionOffer h2 {
    font-size: 22px;
    margin-top: 6px !important;
  }
  .subTerms h2 {
    font-size: 19px;
  }
}

@media only screen and (min-width: 501px) and (max-width: 510px) {
  .subRow {
    width: 100%;
    max-width: 245px;
    padding-left: 5px;
    padding-right: 5px;
  }
  .subscriptionInner {
    padding-left: 5px;
    padding-right: 5px;
  }
  .subscriptionInners {
    padding-left: 5px;
    padding-right: 5px;
  }
  .subscriptionOffer p {
    /* margin-bottom: 0px; */
    margin-bottom: -5px;
  }
  .timeSection {
    font-size: 32px;
    line-height: 42px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    padding: 0px 15px 0px 15px;
    height: 100%;
    min-height: 80px;
    -webkit-box-orient: vertical;
  }
  .amountSection {
    margin: 5px 0px;
  }
  .amountSection p {
    font-size: 30px;
    margin-bottom: 0px;
  }
  .subscriptionbox {
    width: 100%;
    min-width: 144px;
    height: unset !important;
    padding: 10px 0px 15px 0px;
  }
  .subText p {
    font-size: 14px;
    padding: 7px 12px 0px 12px;
    line-height: 23px;
    height: 125px;
  }
  .subBtn button {
    padding: 10px 12px;
    font-size: 14px;
  }
  .storyTittle h2 {
    font-size: 19px;
  }
  .subscriptionOffer h2 {
    font-size: 22px;
    margin-top: 6px !important;
  }
  .subTerms h2 {
    font-size: 19px;
  }
}
@media only screen and (min-width: 511px) and (max-width: 520px) {
  .subRow {
    width: 100%;
    max-width: 250px;
    padding-left: 5px;
    padding-right: 5px;
  }
  .subscriptionInner {
    padding-left: 5px;
    padding-right: 5px;
  }
  .subscriptionInners {
    padding-left: 5px;
    padding-right: 5px;
  }
  .subscriptionOffer p {
    /* margin-bottom: 0px; */
    margin-bottom: -5px;
  }
  .timeSection {
    font-size: 32px;
    line-height: 42px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    padding: 0px 15px 0px 15px;
    height: 100%;
    min-height: 80px;
    -webkit-box-orient: vertical;
  }
  .amountSection {
    margin: 5px 0px;
  }
  .amountSection p {
    font-size: 30px;
    margin-bottom: 0px;
  }
  .subscriptionbox {
    width: 100%;
    min-width: 144px;
    height: unset !important;
    padding: 10px 0px 15px 0px;
  }
  .subText p {
    font-size: 14px;
    padding: 7px 12px 0px 12px;
    line-height: 23px;
    height: 125px;
  }
  .subBtn button {
    padding: 10px 12px;
    font-size: 14px;
  }
  .storyTittle h2 {
    font-size: 19px;
  }
  .subscriptionOffer h2 {
    font-size: 22px;
    margin-top: 6px !important;
  }
  .subTerms h2 {
    font-size: 19px;
  }
}


@media only screen and (min-width: 521px) and (max-width: 525px) {
  .subRow {
    width: 100%;
    max-width: 255px;
    padding-left: 5px;
    padding-right: 5px;
  }
  .subscriptionInner {
    padding-left: 5px;
    padding-right: 5px;
  }
  .subscriptionInners {
    padding-left: 5px;
    padding-right: 5px;
  }
  .subscriptionOffer p {
    /* margin-bottom: 0px; */
    margin-bottom: -5px;
  }
  .timeSection {
    font-size: 32px;
    line-height: 42px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    padding: 0px 20px 0px 20px;
    height: 100%;
    min-height: 84px;
    -webkit-box-orient: vertical;
  }
  .amountSection {
    margin: 5px 0px;
  }
  .amountSection p {
    font-size: 30px;
    margin-bottom: 0px;
  }
  .subscriptionbox {
    width: 100%;
    min-width: 144px;
    height: unset !important;
    padding: 10px 0px 15px 0px;
  }
  .subText p {
    font-size: 14px;
    padding: 7px 20px 0px 20px;
    line-height: 23px;
    height: 125px;
  }
  .subBtn button {
    padding: 10px 12px;
    font-size: 14px;
  }
  .storyTittle h2 {
    font-size: 19px;
  }
  .subscriptionOffer h2 {
    font-size: 22px;
    margin-top: 6px !important;
  }
  .subTerms h2 {
    font-size: 19px;
  }
}
@media only screen and (min-width: 526px) and (max-width: 530px) {
  .subRow {
    width: 100%;
    max-width: 258px;
    padding-left: 5px;
    padding-right: 5px;
  }
  .subscriptionInner {
    padding-left: 5px;
    padding-right: 5px;
  }
  .subscriptionInners {
    padding-left: 5px;
    padding-right: 5px;
  }
  .subscriptionOffer p {
    /* margin-bottom: 0px; */
    margin-bottom: -5px;
  }
  .timeSection {
    font-size: 32px;
    line-height: 42px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    padding: 0px 20px 0px 20px;
    height: 100%;
    min-height: 84px;
    -webkit-box-orient: vertical;
  }
  .amountSection {
    margin: 5px 0px;
  }
  .amountSection p {
    font-size: 30px;
    margin-bottom: 0px;
  }
  .subscriptionbox {
    width: 100%;
    min-width: 144px;
    height: unset !important;
    padding: 10px 0px 15px 0px;
  }
  .subText p {
    font-size: 14px;
    padding: 7px 20px 0px 20px;
    line-height: 23px;
    height: 125px;
  }
  .subBtn button {
    padding: 10px 12px;
    font-size: 14px;
  }
  .storyTittle h2 {
    font-size: 19px;
  }
  .subscriptionOffer h2 {
    font-size: 22px;
    margin-top: 6px !important;
  }
  .subTerms h2 {
    font-size: 19px;
  }
}
@media only screen and (min-width: 531px) and (max-width: 539px) {
  .subRow {
    width: 100%;
    max-width: 259px;
    padding-left: 5px;
    padding-right: 5px;
  }
  .subscriptionInner {
    padding-left: 5px;
    padding-right: 5px;
  }
  .subscriptionInners {
    padding-left: 5px;
    padding-right: 5px;
  }
  .subscriptionOffer p {
    /* margin-bottom: 0px; */
    margin-bottom: -5px;
  }
  .timeSection {
    font-size: 32px;
    line-height: 42px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    padding: 0px 20px 0px 20px;
    height: 100%;
    min-height: 84px;
    -webkit-box-orient: vertical;
  }
  .amountSection {
    margin: 5px 0px;
  }
  .amountSection p {
    font-size: 30px;
    margin-bottom: 0px;
  }
  .subscriptionbox {
    width: 100%;
    min-width: 144px;
    height: unset !important;
    padding: 10px 0px 15px 0px;
  }
  .subText p {
    font-size: 14px;
    padding: 7px 20px 0px 20px;
    line-height: 23px;
    height: 125px;
  }
  .subBtn button {
    padding: 10px 12px;
    font-size: 14px;
  }
  .storyTittle h2 {
    font-size: 19px;
  }
  .subscriptionOffer h2 {
    font-size: 22px;
    margin-top: 6px !important;
  }
  .subTerms h2 {
    font-size: 19px;
  }
}
@media only screen and (min-width: 540px) and (max-width: 549px) {
  .subRow {
    width: 100%;
    max-width: 265px;
    padding-left: 5px;
    padding-right: 5px;
  }
  .subscriptionInner {
    padding-left: 5px;
    padding-right: 5px;
  }
  .subscriptionInners {
    padding-left: 5px;
    padding-right: 5px;
  }
  .subscriptionOffer p {
    /* margin-bottom: 0px; */
    margin-bottom: -5px;
  }
  .timeSection {
    font-size: 32px;
    line-height: 41px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    padding: 0px 27px 0px 26px;
    height: 100%;
    min-height: 80px;
    -webkit-box-orient: vertical;
  }
  .amountSection {
    margin: 5px 0px;
  }
  .amountSection p {
    font-size: 30px;
    margin-bottom: 0px;
  }
  .subscriptionbox {
    width: 100%;
    min-width: 144px;
    height: unset !important;
    padding: 10px 0px 15px 0px;
  }
  .subText p {
    font-size: 14px;
    padding: 7px 25px 0px 25px;
    line-height: 23px;
    height: 125px;
  }
  .subBtn button {
    padding: 10px 12px;
    font-size: 14px;
  }
  .storyTittle h2 {
    font-size: 19px;
  }
  .subscriptionOffer h2 {
    font-size: 22px;
    margin-top: 6px !important;
  }
  .subTerms h2 {
    font-size: 19px;
  }
}
@media only screen and (min-width: 550px) and (max-width: 555px) {
  .subRow {
    width: 100%;
    max-width: 269px;
    padding-left: 5px;
    padding-right: 5px;
  }
  .subscriptionInner {
    padding-left: 5px;
    padding-right: 5px;
  }
  .subscriptionInners {
    padding-left: 5px;
    padding-right: 5px;
  }
  .subscriptionOffer p {
    /* margin-bottom: 0px; */
    margin-bottom: -5px;
  }
  .timeSection {
    font-size: 32px;
    line-height: 41px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    padding: 0px 27px 0px 26px;
    height: 100%;
    min-height: 80px;
    -webkit-box-orient: vertical;
  }
  .amountSection {
    margin: 5px 0px;
  }
  .amountSection p {
    font-size: 30px;
    margin-bottom: 0px;
  }
  .subscriptionbox {
    width: 100%;
    min-width: 144px;
    height: unset !important;
    padding: 10px 0px 15px 0px;
  }
  .subText p {
    font-size: 14px;
    padding: 7px 25px 0px 25px;
    line-height: 23px;
    height: 125px;
  }
  .subBtn button {
    padding: 10px 12px;
    font-size: 14px;
  }
  .storyTittle h2 {
    font-size: 19px;
  }
  .subscriptionOffer h2 {
    font-size: 26px;
    margin-top: 6px !important;
  }
  .subTerms h2 {
    font-size: 19px;
  }
}
@media only screen and (min-width: 556px) and (max-width: 560px) {
  .subRow {
    width: 100%;
    max-width: 273px;
    padding-left: 5px;
    padding-right: 5px;
  }
  .subscriptionInner {
    padding-left: 5px;
    padding-right: 5px;
  }
  .subscriptionInners {
    padding-left: 5px;
    padding-right: 5px;
  }
  .subscriptionOffer p {
    /* margin-bottom: 0px; */
    margin-bottom: -5px;
  }
  .timeSection {
    font-size: 32px;
    line-height: 41px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    padding: 0px 27px 0px 26px;
    height: 100%;
    min-height: 80px;
    -webkit-box-orient: vertical;
  }
  .amountSection {
    margin: 5px 0px;
  }
  .amountSection p {
    font-size: 30px;
    margin-bottom: 0px;
  }
  .subscriptionbox {
    width: 100%;
    min-width: 144px;
    height: unset !important;
    padding: 10px 0px 15px 0px;
  }
  .subText p {
    font-size: 14px;
    padding: 7px 25px 0px 25px;
    line-height: 23px;
    height: 125px;
  }
  .subBtn button {
    padding: 10px 12px;
    font-size: 14px;
  }
  .storyTittle h2 {
    font-size: 19px;
  }
  .subscriptionOffer h2 {
    font-size: 26px;
    margin-top: 6px !important;
  }
  .subTerms h2 {
    font-size: 19px;
  }
}
@media only screen and (min-width: 561px) and (max-width: 569px) {
  .subRow {
    width: 100%;
    max-width: 275px;
    padding-left: 5px;
    padding-right: 5px;
  }
  .subscriptionInner {
    padding-left: 5px;
    padding-right: 5px;
  }
  .subscriptionInners {
    padding-left: 5px;
    padding-right: 5px;
  }
  .timeSection {
    font-size: 32px;
    line-height: 41px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    padding: 0px 28px 0px 28px;
    height: 100%;
    min-height: 80px;
    -webkit-box-orient: vertical;
  }
  .amountSection {
    margin: 5px 0px;
  }
  .amountSection p {
    font-size: 30px;
    margin-bottom: 0px;
  }
  .subscriptionbox {
    width: 100%;
    min-width: 144px;
    height: unset !important;
    padding: 10px 0px 15px 0px;
  }
  .subText p {
    font-size: 14px;
    padding: 7px 25px 0px 25px;
    line-height: 23px;
    height: 125px;
  }
  .subBtn button {
    padding: 10px 12px;
    font-size: 14px;
  }
  .storyTittle h2 {
    font-size: 19px;
  }
  .subscriptionOffer h2 {
    font-size: 26px;
    margin-top: 6px !important;
  }
  .subTerms h2 {
    font-size: 19px;
  }
}
@media only screen and (min-width: 570px) and (max-width: 573px) {
  .subRow {
    width: 100%;
    max-width: 279px;
    padding-left: 5px;
    padding-right: 5px;
  }
  .subscriptionInner {
    padding-left: 5px;
    padding-right: 5px;
  }
  .subscriptionInners {
    padding-left: 5px;
    padding-right: 5px;
  }
  .timeSection {
    font-size: 32px;
    line-height: 41px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    padding: 0px 28px 0px 28px;
    height: 100%;
    min-height: 80px;
    -webkit-box-orient: vertical;
  }
  .amountSection {
    margin: 5px 0px;
  }
  .amountSection p {
    font-size: 30px;
    margin-bottom: 0px;
  }
  .subscriptionbox {
    width: 100%;
    min-width: 144px;
    height: unset !important;
    padding: 10px 0px 15px 0px;
  }
  .subText p {
    font-size: 14px;
    padding: 7px 25px 0px 25px;
    line-height: 23px;
    height: 125px;
  }
  .subBtn button {
    padding: 10px 12px;
    font-size: 14px;
  }
  .storyTittle h2 {
    font-size: 19px;
  }
  .subscriptionOffer h2 {
    font-size: 26px;
    margin-top: 6px !important;
  }
  .subTerms h2 {
    font-size: 19px;
  }
}
@media only screen and (min-width: 574px) and (max-width: 575px) {
  .subRow {
    width: 100%;
    max-width: 282px;
    padding-left: 5px;
    padding-right: 5px;
  }
  .subscriptionInner {
    padding-left: 5px;
    padding-right: 5px;
  }
  .subscriptionInners {
    padding-left: 5px;
    padding-right: 5px;
  }
  .timeSection {
    font-size: 32px;
    line-height: 42px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    padding: 0px 33px 0px 33px;
    height: 100%;
    min-height: 80px;
    -webkit-box-orient: vertical;
  }
  .amountSection {
    margin: 5px 0px;
  }
  .amountSection p {
    font-size: 30px;
    margin-bottom: 0px;
  }
  .subscriptionbox {
    width: 100%;
    min-width: 144px;
    height: unset !important;
    padding: 10px 0px 15px 0px;
  }
  .subText p {
    font-size: 14px;
    padding: 7px 27px 0px 27px;
    line-height: 23px;
    height: 125px;
  }
  .subBtn button {
    padding: 10px 12px;
    font-size: 14px;
  }
  .storyTittle h2 {
    font-size: 19px;
  }
  .subscriptionOffer h2 {
    font-size: 26px;
    margin-top: 6px !important;
  }
  .subTerms h2 {
    font-size: 19px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 599px) {
  .subRow {
    width: 100%;
    max-width: 265px;
    padding-left: 5px;
    padding-right: 5px;
  }
  .subscriptionInner {
    padding-left: 5px;
    padding-right: 5px;
  }
  .subscriptionInners {
    padding-left: 5px;
    padding-right: 5px;
  }
  .timeSection {
    font-size: 32px;
    line-height: 42px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    padding: 0px 33px 0px 33px;
    height: 100%;
    min-height: 80px;
    -webkit-box-orient: vertical;
  }
  .amountSection {
    margin: 5px 0px;
  }
  .amountSection p {
    font-size: 30px;
    margin-bottom: 0px;
  }
  .subscriptionbox {
    width: 100%;
    min-width: 144px;
    height: unset !important;
    padding: 10px 0px 15px 0px;
  }
  .subText p {
    font-size: 14px;
    padding: 7px 27px 0px 27px;
    line-height: 23px;
    height: 125px;
  }
  .subBtn button {
    padding: 10px 12px;
    font-size: 14px;
  }
  .storyTittle h2 {
    font-size: 19px;
  }
  .subscriptionOffer h2 {
    font-size: 26px;
    margin-top: 6px !important;
  }
  .subTerms h2 {
    font-size: 19px;
  }
}

@media only screen and (min-width: 600px) and (max-width: 620px) {
  .subRow {
    width: 100%;
    max-width: 265px;
    padding-left: 5px;
    padding-right: 5px;
  }
  .subscriptionInner {
    padding-left: 5px;
    padding-right: 5px;
  }
  .subscriptionInners {
    padding-left: 5px;
    padding-right: 5px;
  }
  .timeSection {
    font-size: 32px;
    line-height: 42px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    padding: 0px 25px 0px 25px;
    height: 100%;
    min-height: 80px;
    -webkit-box-orient: vertical;
  }
  .amountSection {
    margin: 5px 0px;
  }
  .amountSection p {
    font-size: 30px;
    margin-bottom: 0px;
  }
  .subscriptionbox {
    width: 100%;
    min-width: 144px;
    height: unset !important;
    padding: 10px 0px 15px 0px;
  }
  .subText p {
    font-size: 14px;
    padding: 7px 23px 0px 23px;
    line-height: 23px;
    height: 125px;
  }
  .subBtn button {
    padding: 10px 12px;
    font-size: 14px;
  }
  .storyTittle h2 {
    font-size: 19px;
  }
  .subscriptionOffer h2 {
    font-size: 26px;
    margin-top: 6px !important;
  }
  .subTerms h2 {
    font-size: 19px;
  }
}
@media only screen and (min-width: 621px) and (max-width: 767px) {
  .subRow {
    width: 100%;
    max-width: 265px;
    padding-left: 8px;
    padding-right: 5px;
  }
  .timeSection {
    font-size: 32px;
    line-height: 42px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    padding: 0px 23px 0px 23px;
    height: 100%;
    min-height: 80px;
    -webkit-box-orient: vertical;
  }
  .amountSection p {
    font-size: 30px;
    margin-bottom: 0px;
  }
  .subscriptionbox {
    width: 100%;
    min-width: 144px;
    height: unset !important;
    padding: 10px 0px 15px 0px;
  }
  .subText p {
    font-size: 14px;
    padding: 7px 23px 0px 23px;
    line-height: 23px;
    height: 125px;
  }
  .subBtn button {
    padding: 10px 12px;
    font-size: 14px;
  }
  .storyTittle h2 {
    font-size: 19px;
  }
  .subscriptionOffer h2 {
    font-size: 26px;
    margin-top: 6px !important;
  }
  .subTerms h2 {
    font-size: 19px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .subRow {
    width: 100%;
    max-width: 240px;
    padding-left: 8px;
    padding-right: 5px;
  }
  .timeSection {
    font-size: 32px;
    line-height: 42px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    padding: 0px 10px 0px 10px;
    height: 100%;
    min-height: 80px;
    -webkit-box-orient: vertical;
  }
  .amountSection p {
    font-size: 30px;
    margin-bottom: 0px;
  }
  .subscriptionbox {
    width: 100%;
    min-width: 144px;
    height: unset !important;
    padding: 10px 0px 15px 0px;
  }
  .subText p {
    font-size: 14px;
    padding: 7px 23px 0px 23px;
    line-height: 23px;
    height: 125px;
  }
  .subBtn button {
    padding: 10px 12px;
    font-size: 14px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .subRow {
    width: 100%;
    max-width: 274px;
    padding-left: 5px;
    padding-right: 5px;
  }
  .timeSection {
    font-size: 32px;
    line-height: 42px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    padding: 0px 29px 0px 29px;
    height: 100%;
    min-height: 85px;
    -webkit-box-orient: vertical;
  }
  .amountSection p {
    font-size: 30px;
    margin-bottom: 0px;
  }
  .subscriptionbox {
    width: 100%;
    min-width: 144px;
    height: unset !important;
    padding: 10px 0px 15px 0px;
  }
  .subText p {
    font-size: 14px;
    padding: 7px 44px 0px 44px;
    line-height: 23px;
    height: 125px;
  }
  .subBtn button {
    padding: 10px 12px;
    font-size: 14px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1300px) {
  .subRow {
    width: 100%;
    max-width: 274px;
    padding-left: 5px;
    padding-right: 5px;
  }
  .timeSection {
    font-size: 32px;
    line-height: 42px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    padding: 0px 33px 0px 33px;
    height: 100%;
    min-height: 85px;
    -webkit-box-orient: vertical;
  }
  .amountSection p {
    font-size: 30px;
    margin-bottom: 0px;
  }
  .subscriptionbox {
    width: 100%;
    min-width: 144px;
    height: unset !important;
    padding: 10px 0px 15px 0px;
  }
  .subText p {
    font-size: 14px;
    padding: 7px 30px 0px 30px;
    line-height: 23px;
    height: 125px;
  }
  .subBtn button {
    padding: 10px 12px;
    font-size: 14px;
  }
}
@media only screen and (min-width: 1301px) and (max-width: 1399px) {
  .subRow {
    width: 100%;
    max-width: 274px;
    padding-left: 8px;
    padding-right: 5px;
  }
  .timeSection {
    font-size: 32px;
    line-height: 42px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    padding: 0px 33px 0px 33px;
    height: 100%;
    min-height: 85px;
    -webkit-box-orient: vertical;
  }
  .amountSection p {
    font-size: 30px;
    margin-bottom: 0px;
  }
  .subscriptionbox {
    width: 100%;
    min-width: 144px;
    height: unset !important;
    padding: 10px 0px 15px 0px;
  }
  .subText p {
    font-size: 14px;
    padding: 7px 30px 0px 30px;
    line-height: 23px;
    height: 125px;
  }
  .subBtn button {
    padding: 10px 12px;
    font-size: 14px;
  }
}
@media only screen and (min-width: 1400px) and (max-width: 1533px) {
  .subRow {
    width: 100%;
    max-width: 274px;
    padding-left: 8px;
    padding-right: 5px;
  }
  .timeSection {
    font-size: 32px;
    line-height: 42px;
    display: -webkit-box;
    padding: 0px 33px 0px 33px;
    height: 85px;
    /* min-height: 80px; */
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .amountSection p {
    font-size: 30px;
    margin-bottom: 0px;
  }
  .subscriptionbox {
    width: 100%;
    min-width: 144px;
    height: unset !important;
    padding: 10px 0px 15px 0px;
  }
  .subText p {
    font-size: 14px;
    padding: 7px 30px 0px 30px;
    line-height: 23px;
    height: 125px;
  }
  .subBtn button {
    padding: 10px 12px;
    font-size: 14px;
  }
}

.subscriptionContent {
  margin: 0px auto;
  width: 100%;
  max-width: 824px;
  padding-top: 20px;
}

.borderBottom {
  border-bottom: 1px solid #3e3e3e;
  /* width: 100%;
  max-width: 550px; */
  margin: 0 auto;
  margin-top: 22px;
}

.subscriptionInner {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}

.subscriptionInners {
  display: flex;
  justify-content: flex-start;
}

@media only screen and (max-width: 767px) {
  .subscriptionInner {
    display: flex;
    /* justify-content: flex-start; */
    justify-content: center;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1300px) {
  .subscriptionInner {
    display: flex;
    justify-content: center;
  }
}

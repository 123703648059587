.profileSection {
  padding: 35px 0px;
}

.FormGroup {
  margin-bottom: 20px;
}

.FormSection .FormGroup .FormInput,
.FormSection .FormGroup select {
  padding: 0px 10px;
  height: 40px;
  font-size: 15px;
  width: 100%;
  border-radius: 4px;
  background-color: #333;
  font-weight: 300;
  line-height: 1.6 !important;
}

.FormSection .FormGroup .FormInput,
.FormSection .FormGroup .FormInput::placeholder {
  color: #f0f8ffc4 !important;
}

.FormSection .FormGroup .FormInput,
.FormSection .FormGroup select:focus {
  outline: none;
  border: none;
  box-shadow: none;
}
.FormSection .FormGroup .FormInput,
.FormSection .FormGroup ::placeholder {
  color: #f0f8ffc4 !important;
}

.FormGroupCheck {
  margin-top: 20px;
}

.FormGroupCheck label {
  padding-left: 4px !important;
  cursor: pointer !important;
  font-size: 15px !important;
  font-weight: 300 !important;
  color: #333 !important;
}

.FormGroup .DobWrapper {
  display: flex;
}

.FormGroup .DobField {
  position: relative;
  width: 33.33%;
  padding: 0 5px;
}

.FormGroup .DobField:first-child {
  padding-left: 0;
}

.FormGroup .DobField:last-child {
  padding-right: 0;
}

.FormGroup .DobField svg {
  position: absolute;
  top: 12px;
  right: 8px;
  font-size: 20px;
  color: #f0f0f0;
}

.FormGroup.DobWrapper {
  display: flex;
}

.editProfile .gender {
  max-width: 290px;
}

.weightSelect {
  display: flex;
}

.formBorder {
  margin-bottom: 12px;
}

.updateBtn {
  display: flex;
  margin-left: -4px;
}

.logoutBtn {
  padding-top: 15px;
}

.logoutBtn button {
  font-size: 15px;
  font-weight: 500;
  text-decoration: none;
  color: #333 !important;
  border-radius: 50px;
  padding: 7px 30px;
  border: 1px solid #333;
  line-height: 27px;
  background-color: #fff;
}

.logoutBtn a {
  font-size: 15px;
  font-weight: 500;
  text-decoration: none;
  color: #fff !important;
  border-radius: 50px;
  padding: 7px 30px;
  border: 1px solid #2ea52ee8;
  line-height: 27px;
  background-color: #2ea52ee8;
}

.cancelBtn {
  padding-top: 15px;
  padding-left: 12px;
}
.cancelBtn button {
  font-size: 15px;
  font-weight: 500;
  text-decoration: none;
  color: #fff;
  border-radius: 50px;
  padding: 7px 30px;
  border: 1px solid #3e3e3e;
  line-height: 27px;
  background-color: #333;
}

.inputBlock {
  margin-bottom: 10px;
}
/* .subscriptionPart {
  margin-top: 30px;
  text-align: center;
}

.subscriptionPart h2 {
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  margin-top: 50px;
} */

@media only screen and (min-width: 310px) and (max-width: 400px) {
  .updateBtn {
    display: flex;
  }
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .topRight {
    padding-top: 25px;
  }
}
.formLabel {
  font-size: 15px;
  font-weight: 500;
  /* color: #333; */
  color: #fff;
}

.FormGroup .DobField svg {
  position: absolute;
  top: 12px;
  right: 8px;
  font-size: 20px;
  color: #f0f0f0;
}

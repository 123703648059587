.FooterSection {
  background-color: #202124;
  padding: 20px 0px 10px;
}

.socialLinks {
  display: flex;
  justify-content: center;
  padding-bottom: 10px;
}

.socialLinks img {
  margin: 5px;
  width: 35px;
}

.socialLinks img {
  margin: 8px;
  width: 35px;
  color: #fff;
}

.bottomNavLink {
  color: #87888a;
  font-size: 12px;
  font-weight: 300;
  text-transform: capitalize;
  text-decoration: none;
  padding: 2px 2px;
  line-height: 1;
}

.bottomNavLink:hover {
  text-decoration: underline;
}

.bottomSection {
  display: flex;
  justify-content: center;
}

.bottomSection span {
  color: #87888a;
  font-size: 12px;
  font-weight: 300;
  text-transform: capitalize;
  text-decoration: none;
  padding: 2px 2px;
  line-height: 1;
}

.copyText {
  display: flex;
  justify-content: center;
  padding: 5px 0px 15px 0px;
}

.copyText p {
  color: #87888a;
  font-size: 12px;
  font-weight: 300;
  text-transform: capitalize;
  text-decoration: none;
  padding: 2px 2px;
  line-height: 1;
  letter-spacing: 0.6;
}

.bottomNavLink:hover {
  text-decoration: none;
}


@media only screen and (min-width: 320px) and (max-width: 575px) {
  .socialLinks img {
    margin: 5px;
    width: 35px;
  }

  .socialLinks {
    margin-bottom: 0px;
  }

  .bottomSection {
    display: flex;
    justify-content: center;
  }
}

@media only screen and (min-width: 320px) and (max-width: 372px) {
  .bottomNavLink {
    font-size: 9px;
    font-weight: 300;
    line-height: 13.5px;
    padding: 5px 1px;
  }
}

@media only screen and (min-width: 373px) and (max-width: 575px) {
  .bottomNavLink {
    font-size: 11px;
    font-weight: 300;
    line-height: 13.5px;
    padding: 5px 1px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .bottomNavLink {
    font-size: 12px;
    font-weight: 300;
    line-height: 13.5px;
  }
}


@media only screen and (min-width: 398px) and (max-width: 500px) {
  .copyText p {
    font-size: 11px;
  }

  .bottomNavLink {
    font-size: 11px;
  }
}

@media only screen and (min-width: 338px) and (max-width: 397px) {
  .copyText p {
    font-size: 10px;
  }

  .bottomNavLink {
    font-size: 10px;
  }
}

@media only screen and (max-width: 337px) {
  .copyText p {
    font-size: 8px;
  }

  .bottomNavLink {
    font-size: 8px;
  }
}

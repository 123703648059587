.resetPassword {
  padding: 20px 0px;
}

.FormBlock {
  max-width: 450px;
  margin: 0 auto;
}

.FormSection {
  padding: 15px 50px;
}

.SignupTitle {
  text-align: center;
  margin-bottom: 20px;
}

.SignupTitle img {
  width: 100%;
  max-width: 100px;
}

.SignupTitle h2 {
  font-size: 26px;
  font-weight: 700;
  text-transform: uppercase;
  color: #fff;
  line-height: 40px;
  margin-top: 20px;
  margin-bottom: 10px;
}

.SignupTitle p {
  font-size: 15px;
  font-weight: 300;
  letter-spacing: 0.6px;
  color: #828282;
  line-height: 1.6;
  margin-bottom: 0px;
}

.FormGroup {
  margin-bottom: 10px;
}

.FormSection .FormGroup .FormInput,
.FormSection .FormGroup select {
  border: none;
  padding: 0px 10px;
  height: 40px;
  font-size: 15px;
  width: 100%;
  border-radius: 4px;
  background-color: #333;
  margin-top: 25px;
  color: #f0f8ffc4;
}

.FormSection .FormGroup .FormInput::placeholder {
  font-weight: 300;
  color: #f0f8ffc4;
}

.FormSection .FormGroup .FormInput:focus {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}

.signUpBtn {
  margin-top: 30px;
}

.signUpBtn button {
  width: 100%;
  height: 40px;
  font-size: 15px;
  font-weight: 400;
  color: #fff;
  background: #333;
  border: 1px solid #333 !important;
}

.AccountSection p {
  text-align: center;
  margin-bottom: 0;
  font-size: 15px;
  color: #828282;
  font-weight: 400;
}

.AccountSection p a {
  color: #fff;
  font-weight: 400;
  font-size: 15px;
  text-decoration: none;
}

.GetApp {
  text-align: center;
  margin-top: 20px;
}

.GetApp p {
  margin-bottom: 20px;
  font-size: 15px;
  letter-spacing: 0.6px;
  color: #9c9c9c;
  line-height: 24px;
  font-weight: bold;
}

/* Reset password css */
.resetPasswordSet {
  padding: 40px 0px;
}

.SignupTitleSet {
  text-align: center;
  margin-bottom: 20px;
}

.SignupTitleSet img {
  width: 100%;
  max-width: 67px;
}

.SignupTitleSet h2 {
  margin-top: 20px;
  font-family: Helvetica, Arial, sans-serif !important;
  line-height: 1.1;
  color: inherit;
  margin-bottom: 30px;
  font-weight: 600;
  font-size: 30px;
  color: #fff;
}

.SignupTitleSet p {
  font-size: 15px;
  color: #cfcfcf;
  margin-bottom: 40px;
  line-height: 22px;
  font-family: Helvetica, Arial, sans-serif !important;
}

.FormSection .FormGroupSet .FormInputSet,
.FormSection .FormGroupSet {
  border: none;
  padding: 0px 10px;
  height: 40px;
  font-size: 14px;
  width: 100%;
  border-radius: 4px;
  background-color: #333 !important;
  color: #f0f8ffc4 !important;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

.formField .formGroup .FormInput,
.formField .formGroup .FormInput::placeholder {
  color: #f0f8ffc4 !important;
  background-color: #333;
}

.FormSection .FormGroupSet .FormInputSet:focus {
  outline: none !important;
  border: 2px solid #000 !important;
  box-shadow: none !important;
}

.bgSection {
  background-color: #202124;
}

.SignUpPage {
  background-color: #202124 !important;
}

.navBar {
  position: fixed;
  width: 100%;
  top: 0px;
  left: 0;
  z-index: 999;
  padding: 5px 0px 0;
  background: transparent;
}

.navBar.navbarFixed {
  height: 70px;
  background-color: #000;
  -webkit-animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.navBar .navbar {
  padding-top: 5px;
}

.navBar .navbar-brand {
  height: auto;
  padding: 5px 15px;
}

.navBar.navbarFixed .navbar-brand {
  padding-top: 0px;
}

.navBar .navbar-expand-md {
  align-items: flex-start;
}

.navBar .navbar-brand .logoImg img {
  width: 100%;
  max-width: 100px;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}

.navBar.navbarFixed .navbar-brand .logoImg img {
  max-width: 60px;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}

@media only screen and (max-width: 767px) {
  .navBar {
    height: 70px;
    background: #000;
  }

  .navBar .navbar-brand .logoImg img {
    max-width: 60px;
  }

  .navBar .navbar-collapse {
    background: #000;
  }

  .navBar .navbar-collapse {
    border-top: 1px solid #3a3a3a !important;
  }

  .navBar.navbarFixed .navbar-collapse {
    background-color: #000 !important;
    border-top: 1px solid #3a3a3a !important;
  }

  .navBar .navbar-brand {
    padding-top: 0px;
  }

  .navBar .navbar-expand-md {
    align-items: center;
  }

  .navBar button.navbar-toggler.collapsed {
    margin-right: 30px;
  }

  .navBar button.navbar-toggler {
    margin-right: 30px;
  }

  .navBar .container,
  .navBar .container-sm {
    max-width: 100%;
  }

  .navBar.navbarFixed .container,
  .navBar.navbarFixed .container-sm {
    max-width: 100%;
  }
}

.imageItem {
  transition: transform 0.3s ease;
  transform: scale(1.1);
}

.imageItem {
  width: 100%;
  height: auto;
  object-fit: cover;
  height: 300px;
}

.imageItem {
  object-fit: cover;
  height: 100%;
  width: 100% !important;
}

@media only screen and (min-width: 567px) and (max-width: 767px) {
  .navbar .container {
    max-width: 100% !important;
  }
}

/* fitness story slider css  */
.fitnessSlider .slick-slide img {
  width: 100% !important;
  height: 100%;
}

/* navbar toggle css  */
.headerLogo1 {
  order: 0;
}

.headerLogo1 .navbar-toggler {
  top: 1.8rem;
}

.headerLogo1 .navbar-toggler.collapsed::after {
  display: none;
}

.headerLogo1 .navbar-toggler.collapsed .navbar-toggler-icon,
.headerLogo1 .navbar-toggler .navbar-toggler-icon {
  background: no-repeat center center;
  background-size: 100% 100%;
  content: "";
  width: 30px;
  height: 24px;
  background-image: none;
  position: relative;
  border-bottom: 2px solid #ffffff;
  transition: all 300ms linear;
  vertical-align: middle;
}

.headerLogo1 .navbar-toggler.collapsed .navbar-toggler-icon:before,
.headerLogo1 .navbar-toggler.collapsed .navbar-toggler-icon:after,
.headerLogo1 .navbar-toggler .navbar-toggler-icon:before,
.headerLogo1 .navbar-toggler .navbar-toggler-icon:after {
  width: 30px;
  position: absolute;
  height: 2px;
  background-color: #fff;
  top: 0;
  left: 0;
  content: "";
  z-index: 2;
  transition: all 300ms linear;
  transform: none;
}

.headerLogo1 .navbar-toggler.collapsed .navbar-toggler-icon:after {
  top: 12px;
}

.headerLogo1 .navbar-toggler .navbar-toggler-icon {
  border-color: transparent;
}

.headerLogo1 .navbar-toggler .navbar-toggler-icon::before {
  transform: rotate(-45deg);
  top: 12px;
}

.headerLogo1 .navbar-toggler .navbar-toggler-icon::after {
  transform: rotate(45deg);
  top: 12px;
}

/* fitness story slider css */
.fitnessSlider .slick-slide img {
  padding: 0px 3px 0px 3px;
}

/* signup form css */
.SignUpPage .ReactFlagsSelect-module_flagsSelect__2pfa2 {
  padding: 0px !important;
  height: 40px;
  font-size: 14px !important;
  width: 100%;
  border-radius: 4px;
  background: #333 !important;
  color: #757575 !important;
  /* background: #fafafa !important; */
}

.SignUpPage .ReactFlagsSelect-module_selectBtn__19wW7:after {
  border-top: 5px solid #f0f0f0 !important;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 0;
}

.SignUpPage .ReactFlagsSelect-module_selectBtn__19wW7 {
  border: none !important;
  padding: 4px 10px 4px 0px !important;
  background-color: #333;
}

.SignUpPage .ReactFlagsSelect-module_selectBtn__19wW7::placeholder {
  color: #f0f0f0 !important;
}

.SignUpPage .ReactFlagsSelect-module_selectValue__152eS {
  pointer-events: none;
  display: flex;
  font-size: 15px;
  align-items: center;
  font-weight: 300;
  /* color: #f0f0f0; */
  color: #f0f8ffc4;
}

.SignUpPage .form-select:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: none;
}

/* contact us css */
.formGroup .custom-select:focus {
  border: none;
  outline: 0;
  box-shadow: none;
}

.errorMsg {
  color: #ff0000;
  font-size: 14px;
  font-weight: 500;
}

/*  about new css  */
.middle .box {
  width: 100%;
  height: 45px;
  background-color: transparent;
  transition: all 250ms ease;
  will-change: transition;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  position: relative;
  font-weight: 400;
  line-height: 42px;
  border: 1px solid #3e3e3e !important;
  border-radius: 4px;
  color: #9c9c9c;
}

.gender label {
  width: 50%;
  margin: 0;
}

.middle input[type="radio"] {
  display: none;
}

.middle input[type="radio"]:checked+.box span {
  color: #121212;
  font-size: 15px;
  font-weight: 500;
}

.edit .middle input[type="radio"]:checked+.box span {
  color: white !important;
}

.edit .middle input[type="radio"]:checked+.box span svg {
  color: white !important;
}

.middle .box span {
  transition: all 300ms ease;
  user-select: none;
  color: #fff;
  font-size: 15px;
  font-weight: 500;
}

.middle .box span svg {
  font-size: 18px;
  color: #fff;
  margin-right: 8px;
  margin-top: -3px;
}

.middle input[type="radio"]+.box span:before {
  opacity: 1 !important;
  color: #fff;
}

.edit .middle input[type="radio"]+.box span:before {
  opacity: 1 !important;
  color: #9c9c9c !important;
}

.middle input[type="radio"]:checked+.box span svg {
  color: #121212;
  font-size: 20px;
  margin-right: 5px;
}

.middle input[type="radio"]:checked+.box {
  background-color: #f0f0f0 !important;
}

.middle label .rad1 {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.middle label .rad2 {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.unit.middle.dFlex label {
  width: 20%;
  height: 35px;
  margin-bottom: 0;
  line-height: 35px !important;
}

.unit.middle.dFlex label .box {
  height: 35px;
  line-height: 35px;
}

.unit {
  display: flex;
  justify-content: center !important;
  margin-bottom: 10px;
}

.heightbox {
  display: flex;
  margin-bottom: 10px;
  justify-content: center;
}

.formField .form-group input,
.formField .form-group select {
  border: 1px solid #efefef;
  padding: 0px 10px;
  height: 40px;
  font-size: 14px;
  width: 100%;
  border-radius: 4px;
  background: #fafafa !important;
}

.select-options {
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  z-index: 999;
  margin: 0;
  padding: 0;
  list-style: none;
  background-color: #ffffff;
  color: #333;
  border: 1px solid #efefef;
  max-height: 250px;
  overflow: auto;
}

.selectCm {
  display: flex !important;
  justify-content: center !important;
}

.selectBox .css-13cymwt-control {
  width: 57%;
  display: flex;
  margin-left: 75px;
}

.WeightField {
  width: 57%;
  margin: 0 auto;
}

.WeightField select.FormInput.form-select {
  padding: 0px 7px !important;
  max-width: 140px !important;
  height: 40px !important;
  font-size: 14px !important;
  width: 100% !important;
  border-radius: 4px !important;
  background-color: #333 !important;
  margin: 0px auto;
  color: aliceblue !important;
  border: 1px solid #333;
}

select.FormInput.form-select {
  padding: 0px 7px !important;
  max-width: 72px !important;
  height: 40px !important;
  font-size: 14px !important;
  margin: 0px 3px 0px 4px;
  width: 100% !important;
  border-radius: 4px !important;
  background-color: #fafafa !important;
}

select.FormInput.form-select:focus {
  box-shadow: none;
}

.selectCm select {
  border: 1px solid #efefef !important;
  padding: 0px 10px !important;
  height: 40px !important;
  font-size: 15px !important;
  width: 20% !important;
  margin-left: 6px;
  color: #757575;
  font-weight: 300;
}

.tabSection button#uncontrolled-tab-example-tab-contact {
  color: red !important;
}

.tabSection button {
  color: #fff;
}

.tabSection button:hover {
  color: #fff !important;
}

.tabSection .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #fff;
  background-color: #202124;
  border-color: #3e3e3e;
}

.tabSection .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link:hover {
  border-color: #3e3e3e;
}

.nav-tabs {
  border-color: #3e3e3e !important;
}

.subscriptionInners {
  display: flex;
  justify-content: flex-start;
}

/* edit profile css */
.edit.gender.middle {
  max-width: 290px;
  margin-bottom: 10px;
}

.editSelectItem {
  display: flex !important;
}

.editSelectItem select.FormInput.form-select {
  border: none;
  padding: 0px 10px !important;
  height: 40px !important;
  font-size: 14px !important;
  margin: 0px 6px 0px 0px !important;
  max-width: 148px !important;
  background-color: #333 !important;
  color: #f0f8ffc4 !important;
}

.weightRow select.FormInput.form-select {
  border: none;
  padding: 0px 10px !important;
  height: 40px !important;
  font-size: 14px !important;
  margin: 0px 6px 0px 0px !important;
  max-width: 148px !important;
  background-color: #333 !important;
  color: #f0f8ffc4 !important;
}

.edit.middle.dFlex label {
  width: 75px;
}

.weightRadio {
  padding-left: 12px;
}

.edit.middle.dFlex label .box {
  height: 40px;
  line-height: 35px;
  border-radius: 4px;
}

.weightSelect {
  display: flex;
  margin-top: 12px;
}

.weightSelect select {
  border: 1px solid #efefef !important;
  padding: 0px 10px !important;
  height: 40px !important;
  font-size: 14px !important;
  width: 20% !important;
  margin-left: 6px;
}

.profileImageSet {
  width: 120px;
  height: 120px;
  border-radius: 100%;
  position: relative;
}

.profileImageSet img {
  width: 120px;
  height: 120px;
  border-radius: 100%;
  object-fit: cover;
  border: 1px solid #333;
}

.profileImageSet svg {
  width: 35px;
  height: 35px;
  cursor: pointer;
  position: absolute;
  color: #fff;
  right: -6px;
  bottom: 10px;
  border-radius: 50%;
  background: #333;
  padding: 8px;
}

.profileIcon svg {
  width: 35px;
  height: 35px;
  cursor: pointer;
  margin-right: 23px;
  position: absolute;
  bottom: 1px;
  color: #fff;
  right: 100px;
  border-radius: 50%;
  background: #111;
  padding: 8px;
}

.profileImg {
  position: relative;
}

.profileImgCls {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .FormSection {
    background: #fff;
    padding: 15px 0px !important;
  }
}

.WeightSelect .FormInput.form-select {
  border: 1px solid #efefef !important;
  padding: 0px 10px;
  max-width: 148px !important;
  height: 40px;
  font-size: 14px;
  width: 100%;
  border-radius: 4px;
  background-color: #fafafa !important;
}

.WeightSelect select {
  border: 1px solid #efefef !important;
  padding: 0px 10px !important;
  height: 40px !important;
  font-size: 14px !important;
  width: 270% !important;
  margin-left: 6px;
}

.weightRow {
  display: flex;
}

@media only screen and (min-width: 320px) and (max-width: 339px) {
  .profileIcon svg {
    position: absolute;
    top: 82px;
    right: 63px !important;
    padding: 8px;
  }

  /* .successModal .modal-dialog {
    width: 100% !important;
    max-width: 280px !important;
  } */
}

@media only screen and (min-width: 340px) and (max-width: 400px) {
  .profileIcon svg {
    position: absolute;
    top: 82px;
    right: 85px !important;
    padding: 8px;
  }
}

@media only screen and (min-width: 401px) and (max-width: 479px) {
  .profileIcon svg {
    position: absolute;
    top: 82px;
    right: 75px !important;
    padding: 8px;
  }

  .WeightField {
    display: flex;
    justify-content: center;
  }
}

@media only screen and (min-width: 320px) and (max-width: 400px) {
  .selectCm select {
    width: 30% !important;
  }

  .editSelectItem select {
    padding: 0px 10px !important;
    height: 40px !important;
    font-size: 14px !important;
    width: 24% !important;
    margin-left: 6px;
  }

  .edit.middle.dFlex label {
    width: 60px;
  }

  .WeightField {
    width: 57%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
  }
}

@media only screen and (min-width: 400px) and (max-width: 767px) {
  select.FormInput.form-select {
    padding: 0px 7px !important;
    max-width: 100px !important;
    height: 40px !important;
    font-size: 14px !important;
    margin: 0px 3px 0px 4px;
    width: 100% !important;
    border-radius: 4px !important;
    background-color: #fafafa !important;
  }

  .subRow {
    margin-left: 12px !important;
  }
}

.weightInput {
  padding-left: 5px;
}

.weightRow select {
  margin-right: 5px;
  border: none;
}

.fitnessSlider .slick-slider .slick-slide {
  padding: 0 5px;
}

@media (min-width: 576px) and (max-width: 768px) {
  .containerManage .container {
    max-width: unset !important;
  }

  .WeightField {
    display: flex;
    justify-content: center;
  }
}

.curserRemove {
  cursor: unset !important;
}

/* accordian css */
.faqBlock .accordion-button:focus {
  z-index: 3;
  border-color: #dee1e4;
  outline: 0;
  box-shadow: unset !important;
  background-color: #fff;
}

.faqBlock .accordion-button:not(.collapsed) {
  box-shadow: unset !important;
}

.faqBlock .accordion-button {
  color: #fff !important;
  font-size: 17px;
  background-color: #202124 !important;
  font-weight: 500;
}

.faqBlock .accordion-item {
  color: #fff !important;
  background-color: #202124 !important;
}

.faqBlock .accordion-item {
  margin-bottom: 20px;
  border: 1px solid #3e3e3e;
}

.faqBlock .accordion-item:first-of-type {
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
}

.faqBlock .accordion-item:last-of-type {
  border-bottom-right-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.faqBlock .accordion-button {
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

@media screen and (min-width: 310px) and (max-width: 341px) {
  .faqBlock .accordion-button {
    font-size: 13px;
    padding-left: 7px;
    padding-right: 7px;
  }

  .faqBlock .accordion-body p {
    font-size: 13px;
  }
}

@media screen and (min-width: 342px) and (max-width: 370px) {
  .faqBlock .accordion-button {
    font-size: 14px;
    padding-left: 7px;
    padding-right: 14px;
  }

  .faqBlock .accordion-body p {
    font-size: 14px;
  }
}

@media screen and (min-width: 371px) and (max-width: 400px) {
  .faqBlock .accordion-button {
    font-size: 14px;
    padding-left: 12px;
    padding-right: 16px;
  }

  .faqBlock .accordion-body p {
    font-size: 14px;
  }
}

/* new .css */
.faqBlock .accordion-button::after {
  background-image: url("../Images/white-down-arrow.png") !important;
  margin-top: 5px !important;
  background-size: 16px !important;
}

.SignUpPage .ReactFlagsSelect-module_flagsSelect__2pfa2::placeholder {
  color: #fff !important;
}

.editSelectItem .FormInput svg {
  position: absolute;
  top: 12px;
  right: 8px;
  font-size: 20px;
  color: #f0f0f0;
}

.weightRow svg {
  color: #fff;
}

.editSelectItem {
  color: #fff;
}

.weightRow .form-select ::after {
  background-image: url("../Images/white-down-arrow.png") !important;
  margin-top: 5px !important;
  background-size: 16px !important;
}

.modal-body {
  background-color: #333 !important;
}

@media only screen and (min-width: 310px) and (max-width: 349px) {
  .tabClass li button {
    padding-right: 8px;
    padding-left: 8px;
    font-size: 10px;
  }
}

@media only screen and (min-width: 350px) and (max-width: 397px) {
  .tabClass li button {
    padding-right: 8px;
    padding-left: 8px;
    font-size: 13px;
  }
}

@media only screen and (min-width: 398px) and (max-width: 447px) {
  .tabClass li button {
    padding-right: 11px;
    padding-left: 11px;
    font-size: 14px;
  }

  .unit.middle.dFlex label {
    width: 23% !important;
    height: 35px;
    margin-bottom: 0;
    line-height: 35px !important;
  }
}

.fade.successModal.modal.show {
  padding-left: 0px !important;
}

.successModal .modal-content {
  width: 100%;
  max-width: 450px !important;
}

@media screen and (max-width: 767px) {
  .successModal .modal-content {
    width: 100%;
    max-width: 450px !important;
    margin: 0 auto;
    border-radius: 0;
    border: none;
    overflow: auto;
    height: 100vh;
  }

  .homefitnessSlider .slick-slide img {
    width: 100% !important;
  }
}

@media only screen and (min-width: 320px) and (max-width: 567px) {
  select.FormInput.form-select {
    padding: 0px 7px !important;
    max-width: 100px !important;
    height: 40px !important;
    font-size: 14px !important;
    margin: 0px 3px 0px 4px;
    width: 100% !important;
    border-radius: 4px !important;
    background-color: #fafafa !important;
  }
}

/* signup flow about*/
.aboutMiddle .box {
  width: 100%;
  height: 45px;
  background-color: transparent !important;
  transition: all 250ms ease;
  will-change: transition;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  position: relative;
  font-weight: 400;
  line-height: 42px;
  border: 1px solid #333;
  border-radius: 5px;
  color: #f0f0f0;
}

.aboutMiddle .box span {
  font-size: 18px;
  font-weight: 500;
  line-height: 30px;
  color: #fff !important;
}

.aboutMiddle .box span svg {
  font-size: 18px;
  color: #fff !important;
  margin-right: 8px;
  margin-top: -3px;
}

.aboutMiddle input[type="radio"]:checked+.box span {
  color: #121212 !important;
  font-size: 18px;
  font-weight: 500;
}

.aboutMiddle input[type="radio"]:checked+.box span svg {
  color: #121212 !important;
  font-size: 20px;
  margin-right: 5px;
}

.homefitnessSlider .slick-slide img {
  width: 100% !important;
  height: 100%;
}

.homefitnessSlider .slick-slide img {
  padding: 0px 3px 0px 3px;
}

.capitalLetter .form-control {
  text-transform: capitalize !important;
}

.DobField .react-datepicker__input-container input {
  height: 40px;
  width: 100%;
  background-color: #333 !important;
  color: #f0f8ffc4 !important;
  border: 1px solid #333;
}

.main {
  min-height: calc(100vh - 182px);
  overflow-x: hidden;
}

.flagSelect .ReactFlagsSelect-module_selectOptions__3LNBJ {
  border: 1px solid #bdbbbb;
  border-radius: 3px;
  background: #333 !important;
  color: #fff !important;
  margin-top: 8px;
  padding: 8px 0;
  max-height: 180px;
  overflow: auto;
}

.editSelectItem .form-select {
  background-image: url("../Images/white-down-arrow.png");
}

.weightRow .form-select {
  background-image: url("../Images/white-down-arrow.png") !important;
}

.datePicker .react-datepicker__input-container::placeholder {
  color: #fff !important;
}

.datePicker .react-datepicker-wrapper input {
  border: 1px solid #333 !important;
  padding: 0px 10px;
  height: 40px;
  font-size: 15px;
  width: 100%;
  border-radius: 4px;
  color: #f0f8ffc4 !important;
  background-color: #333;
  font-weight: 300 !important;
  line-height: 1.6 !important;
  touch-action: manipulation;
}

.datePicker .react-datepicker-wrapper {
  display: block !important;
}

.datePicker .react-datepicker-wrapper input[type="text"]:focus {
  border: 1px solid #333 !important;
  outline: unset !important;
}

.DobField .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before,
.DobField .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
  display: none !important;
}

.DobField .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before,
.DobField .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::after {
  display: none !important;
}

.flagSelect .ReactFlagsSelect-module_selectBtn__19wW7-:hover {
  background-color: #333;
  border: 1px solid #333;
}

.aboutSelectCm {
  display: flex !important;
  justify-content: center !important;
}

.aboutSelectCm .FormInput.form-select {
  border: 1px solid #333 !important;
  padding: 0px 10px !important;
  height: 40px !important;
  font-size: 15px !important;
  width: 100% !important;
  max-width: 70px !important;
  margin-left: 6px;
  color: aliceblue;
  font-weight: 300;
  border-radius: 4px;
  background-color: #333 !important;
  margin-left: 2px;
}

.WeightField .form-select {
  background-image: url("../Images/white-down-arrow.png") !important;
}

.aboutSelectCm .form-select {
  background-image: url("../Images/white-down-arrow.png") !important;
}

@media only screen and (max-width: 767px) {
  .homeContent {
    max-width: unset !important;
    padding-left: 3px;
    padding-right: 3px;
  }
}

@media only screen and (max-width: 575px) {
  .homeContent {
    max-width: unset !important;
    padding-left: 3px;
    padding-right: 3px;
  }

  .sliderContent {
    padding-left: 3px;
    padding-right: 3px;
    max-width: unset !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .homeContent {
    padding: 0px;
  }
}

.textSection {
  color: #a5a5a5;
}

.textSection p strong {
  color: #fff;
}

.newSubmain {
  min-height: calc(100vh - 125px);
  overflow-x: hidden;
}

.fitnessSlider {
  width: 100%;
  height: 100%;
}

@media only screen and (max-width: 767px) {
  .subContainer {
    max-width: unset !important;
    padding-left: 3px;
    padding-right: 3px;
  }
}

.payPal .paypal-button-row {
  height: 45px !important;
}

.md .navbar {
  padding-top: 8px !important;
}

/* new css */
.navBar.navbarFixed {
  height: 70px;
  background-color: #000;
  -webkit-animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.activeShow a.active {
  font-weight: 600 !important;
  border: 1px solid #fff !important;
  color: #fff !important;
}

* {
  scrollbar-width: 2px;
  scrollbar-color: #d3d3d3c9 transparent;
}

*::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}

*::-webkit-scrollbar-corner {
  display: none;
}

*::-webkit-scrollbar-thumb:vertical {
  background-color: #d3d3d383;
  border-radius: 5px;
  border: 1px solid transparent;
}

*::-webkit-scrollbar-thumb:horizontal {
  background-color: #d3d3d383;
  border-radius: 20px;
  border: 1px solid transparent;
}

@media only screen and (max-width: 767px) {
  .containerHide {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .planContainer {
    max-width: unset !important;
  }
}

.aboutMain {
  min-height: calc(100vh - 115px);
  overflow-x: hidden;
}

/* subscription details auto recurring alert popup design  */
.swalAlert .swal2-html-container {
  text-align: center;
  font-weight: 500;
  margin-bottom: 40px;
  margin-top: 25px;
}

.swalAlert .swal2-title {
  text-align: center;
  font-weight: 500;
}

.swalAlert button.swal2-confirm.swal2-styled.swal2-default-outline {
  color: #fff;
  border-radius: 150px;
  background-color: #333 !important;
  border: 1px solid #3e3e3e !important;
  font-weight: 500;
  padding: 8px 40px;
}

.swalAlert button.swal2-confirm.swal2-styled.swal2-default-outline:hover {
  background-image: none !important;
}

.swalAlert button.swal2-confirm.swal2-styled.swal2-default-outline:focus {
  box-shadow: unset !important;
}

.swalAlert button.swal2-cancel.swal2-styled.swal2-default-outline:focus {
  box-shadow: unset !important;
}

.swalAlert button.swal2-cancel.swal2-styled.swal2-default-outline:hover {
  background-image: none !important;
}

.swalAlert button.swal2-cancel.swal2-styled {
  background-color: #333;
  color: #d33;
  border: 1px solid #3e3e3e;
  border-radius: 150px;
  font-weight: 500;
  padding: 8px 40px;
}

.flagSelect .ReactFlagsSelect-module_filterBox__3m8EU {
  background: #333 !important;
}

.flagSelect .ReactFlagsSelect-module_filterBox__3m8EU input {
  background-color: #333;
  border: none;
  color: #fff;
}

/* sale purchased tab alert popup design  */
.swalAlertBox .swal2-html-container {
  /* margin-bottom: 40px; */
  /* margin-top: 25px; */
  color: #c5c6c9;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.8;
  text-align: left;
}

.swalAlertBox button.swal2-confirm .swal2-styled {
  background-color: #333 !important;
  color: #fff;
  border: 1px solid #3e3e3e;
  border-radius: 150px;
  font-weight: 500;
  padding: 8px 40px;
}

/* .swalAlertBox .swal2-html-container {
  text-align: center;
  font-weight: 500;
  margin-bottom: 40px;
  margin-top: 25px;
  color: #fff;
} */

.swalAlertBox .swal2-title {
  text-align: center;
  font-weight: 600;
  color: #fff;
  text-transform: uppercase;
  font-size: 23px;
  padding-top: 0px;
}

.swalAlertBox button.swal2-confirm.swal2-styled {
  background-color: #7dbec1 !important;
  color: #fff;
  border-radius: 150px;
  border: 1px solid #7dbec1;
  font-weight: 500;
  padding: 8px 50px;
}

.swalAlertBox button.swal2-confirm.swal2-styled:focus {
  box-shadow: unset !important;
}

.swalAlertBox button.swal2-confirm.swal2-styled:hover {
  background-image: none !important;
}

.swalAlertBox .swal2-success-ring {
  position: absolute !important;
  z-index: 2 !important;
  top: -0.25em !important;
  left: -0.25em !important;
  box-sizing: content-box !important;
  width: 60px !important;
  height: 60px !important;
  border: 0.25em solid rgba(101, 224, 143, 255) !important;
  border-radius: 50% !important;
}

.swalAlertBox .swal2-success-line-long {
  display: block !important;
  position: absolute !important;
  z-index: 2 !important;
  height: 0.3125em !important;
  border-radius: 0.125em !important;
  background-color: #65e18f !important;
  top: 31px !important;
  right: 25px !important;
  width: 35px !important;
  transform: rotate(-45deg) !important;
}

.swalAlertBox .swal2-success-line-tip {
  display: block !important;
  position: absolute !important;
  z-index: 2 !important;
  height: 0.3125em !important;
  border-radius: 0.125em !important;
  background-color: #65e18f !important;
  top: 37px !important;
  left: 10px !important;
  width: 20px !important;
  transform: rotate(45deg) !important;

}

.swalAlertBox.swal2-popup.swal2-modal.swalAlertBox.swal2-icon-success.swal2-show {
  /* margin: 10px auto !important; */
  padding: 10px !important;
  width: 100% !important;
  max-width: 600px !important;
  border-radius: 15px !important;
}

.swalAlertBox .swal2-icon.swal2-success.swal2-icon-show {
  margin-top: 10px !important;
}

.swalAlertBox .swal2-actions {
  margin-top: 0px !important;
}

.swal2-container.swal2-center.swal2-backdrop-show {
  z-index: 2147483647 !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
  transform: translate3d(0, 0, 0) !important;
  background-color: rgba(0, 0, 0, 0.8) !important;
  background: radial-gradient(50% 50%, ellipse closest-corner, rgba(0, 0, 0, 0.6) 1%, rgba(0, 0, 0, 0.8) 100%) !important;
}

.ManageTopSpace {
  margin-top: 20px;
}
.subscriptionPart {
  margin-top: 40px;
  text-align: center;
}

.subscriptionPart h2 {
  color: #fff;
  font-size: 15px;
  font-weight: 500;
}

.logoutBtn {
  padding-top: 10px;
}

.logoutBtn button {
  font-size: 15px;
  font-weight: 400;
  text-decoration: none;
  color: #fff !important;
  border-radius: 50px;
  padding: 8px 20px;
  background-color: #4aa82b;
  border: 1px solid #4aa82b;
  display: inline-block;
}

.subscriptionDataDetails {
  border: 1px solid #3e3e3e;
  width: 100%;
  max-width: 500px;
}

.SubscriptionCurrency {
  border-bottom: 1px solid #3e3e3e;
  padding: 10px;
  background-color: #333;
}

.SubscriptionCurrency h2 {
  color: #fff;
  text-align: right;
  font-size: 20px;
  margin-right: 15px;
  margin-bottom: 0px;
}

.subscriptionData {
  display: flex;
  border-bottom: 1px solid #3e3e3e;
  padding: 10px;
}

.subscriptionData h6 {
  margin-left: 15px;
  color: #fff;
  font-size: 16px;
  width: 250px;
  font-weight: 400;
  margin-bottom: 0px !important;
}

.subscriptionData p {
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0px !important;
  text-transform: capitalize;
}

.subscriptionCheckBox {
  padding: 12px;
}

.subscriptionCheckBox label {
  color: #fff;
  font-weight: 700;
  font-size: 15px;
}

@media only screen and (max-width: 410px) {
  .subscriptionData h6 {
    margin-left: 8px;
    font-size: 12px;
    width: 170px;
  }

  .subscriptionData p {
    font-size: 12px;
  }

  .subscriptionCheckBox h3 {
    font-size: 14px !important;
    margin-left: 6px !important;
  }
}

.subscriptionCheckBox h3 {
  color: #fa4a4a;
  font-weight: 700;
  font-size: 15px;
  margin-left: 12px;
}

.subscriptionBtn {
  padding-top: 10px;
}

.subscriptionBtn button {
  font-size: 15px;
  font-weight: 400;
  text-decoration: none;
  color: #fff !important;
  border-radius: 50px;
  padding: 8px 20px;
  border: 1px solid #fa4a4a;
  display: inline-block;
  background-color: #fa4a4a;
}

/* subscription tab new designs css */

.SubscriptionCurrency h1 {
  color: #c3c5c7;
  text-align: left;
  font-size: 20px;
  margin-bottom: 0px;
  margin-left: 6px;
}

.subscriptionDatas {
  display: flex;
  border-bottom: 1px solid #3e3e3e;
  padding: 17px 20px;
  justify-content: space-between;
  align-items: center;
}

.subscriptionDatas h6 {
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0px !important;
}

.subscriptionDatas p {
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0px !important;
  text-transform: capitalize;
}

.subscriptionDatas h4 {
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0px !important;
  line-height: 27px;
}

.subscriptionDatas h5 {
  color: #87888a;
  font-size: 14px;
  font-weight: 500;
  margin-top: 10px;
  line-height: 22px;
}

.subscriptionDatas span {
  color: #fa4a4a;
}

.subscriptionBtns {
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 500px;
}

.cancelButtonAlign {
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 500px;
  padding-bottom: 10px;
}

.cancelText {
  color: #87888a;
  font-size: 14px;
  font-weight: 500;
  margin-top: 10px;
}

.cancelTrialButton {
  padding: 0px 20px;
  margin-bottom: 48px;
}

.buttonBottonSpace {
  padding-bottom: 80px;
}

.subscriptionRemoveBorder {
  border-bottom: unset !important;
  padding-bottom: 5px !important;
}

@media only screen and (max-width: 410px) {
  .subscriptionDatas h6 {
    font-size: 12px !important;
  }

  .subscriptionDatas p {
    font-size: 12px !important;
  }

  .subscriptionDatas h4 {
    font-size: 12px !important;
    line-height: 21px;
  }

  .SubscriptionCurrency h1 {
    font-size: 18px;
    margin-left: 0px;
  }

  .subscriptionDatas {
    padding: 15px 10px;
  }

  .cancelTrialButton {
    padding: 0px 10px;
  }

  .cancelText {
    font-size: 11px;
  }

  .subscriptionDatas h5 {
    font-size: 12px !important;
    line-height: 21px;
  }

}
.formSection {
  padding: 15px 50px;
  max-width: 450px;
  margin: 0 auto;
}

.aboutWrapper{
  padding-top: 40px;

}

.topHeading {
  display: flex;
  justify-content: center;
}

.topHeading h3 {
  font-weight: 500;
  /* color: #000; */
  color: #fff;
  font-size: 22px;
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 12px;
  text-align: center;
  margin-left: 22px;
}

.topHeading span {
  color: red;
  font-size: 20px;
  vertical-align: middle;
  margin-left: 5px;
  margin-top: -3px;
}
.formBlock {
  padding-bottom: 20px;
}

.inputBlock {
  margin-top: 40px;
  padding-top: 40px;
  border-top: 1px solid #3e3e3e;
}

.inputBlock p {
  letter-spacing: 0.6px;
  color: #e5e5e5;
  font-size: 15px;
}

.labelTitle {
  text-align: center;
  margin-bottom: 20px;
}
.signUpBtn {
  margin-bottom: 10px;
  margin-top: 30px;
  display: flex;
}

.signUpBtn button {
  width: 100%;
  height: 40px;
  font-size: 15px;
  font-weight: 400;
  color: #fff;
  background: #333;
  border-radius: 5px;
  border: 1px solid #333 !important;
  margin-left: 4px;
  margin-right: 4px;

}

.signUpBtn button a {
  font-size: 15px;
  font-weight: 400;
  color: #fff !important;
  text-decoration: none;
}

.labelTitle h3 {
  color: #fff;
  font-size: 22px;
  font-weight: 500;
}

/* new css */
@media screen and (max-width: 479px) {
  .topHeading h3 {
    font-size: 18px;
  }

  .labelTitle h3 {
    font-size: 18px;
  }

  .inputBlock p {
    font-size: 15px;
    padding: 0 !important;
    margin: 0 auto !important;
    font-weight: 400 !important;
    color: #828282 !important;
  }
}

@media only screen and (max-width: 767px) {
  .formSection {
    padding: 20px 0px;
  }
}

.signupDone {
  background-color: #f5f5f5;
  padding: 90px 0px;
}

.FormBlock {
  max-width: 450px;
  margin: 0 auto;
}

.SignupTitle {
  text-align: center;
  margin-bottom: 20px;
}

.SignupTitle img {
  width: 100%;
  max-width: 120px;
}

.SignupTitle h2 {
  font-size: 24px;
  font-weight: 500;
  color: #fff;
  margin-top: 20px;
  margin-bottom: 2px;
}

.SignupTitle p {
  font-size: 20px;
  font-weight: 400;
  color: #fff;
}

.signupPara h5 {
  font-size: 13px;
  font-weight: 300;
  color: #c7c7c6;
  line-height: 2;
  margin-bottom: 20px;
}

.signupPara span {
  font-size: 14px;
  font-weight: 500;
  color: #626262;
}

.signUpBtn {
  margin-bottom: 15px;
  margin-top: 35px;
}

.signUpBtn button {
  width: 100%;
  height: 40px;
  font-size: 15px;
  font-weight: 600;
  color: #fff;
  background: #111;
  border: 1px solid #111 !important;
}

.AppLink a img {
  width: 100%;
  max-width: 130px;
  margin-right: 6px;
}

.GetApp {
  text-align: center;
  margin-top: 33px;
}

.GetApp p {
  font-size: 15px;
  color: #c7c7c6;
  font-weight: 400;
}

.signupPara {
  text-align: center;
  margin-bottom: 5px;
  margin-top: 5px;
}

.doneBtn {
  text-align: center;
  margin-bottom: 20px;
  margin-top: 30px !important;
  max-width: 200px;
  margin: 0px auto;
}

.doneBtn button {
  border-radius: 150px;
  padding: 11px 38px;
  background-color: #11111182;
  color: #fff;
  text-decoration: none;
  font-size: 15px;
  font-weight: 500;
  border: 1px solid #11111100;
}

@media only screen and (max-width: 479px) {
  .AppLink a img {
    width: 100%;
    max-width: 110px;
  }
}

.AppLink p {
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 14px;
  letter-spacing: 0.6px;
  color: #9c9c9c;
  line-height: 24px;
  font-weight: bold;
  text-transform: capitalize;
}

.AppLink a {
  display: inline-block;
  overflow: hidden;
  border-radius: 6px;
  margin-right: 6px;
  margin-bottom: 6px;
}

.AppLink a img {
  width: 100%;
  max-width: 140px;
}

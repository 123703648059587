.planHeading h1 {
  font-weight: 800;
  font-size: 30px;
  color: #fff;
  margin-top: 0px;
  text-align: center;
  line-height: 40px;
  margin-bottom: 5px;
}

.planHeading p {
  font-weight: 300;
  font-size: 15px;
  line-height: 2 !important;
  color: #c6c5c9;
  text-align: left;
}

@media only screen and (max-width: 767px) {
  .planHeading h1 {
    font-size: 26px;
  }
}

/* fitnessplan image section css */
.homePlan {
  position: relative;
  margin-bottom: 20px;
}

.homePlan .planImg:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.image_grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
  margin-top: 37px;
}

.image_middle {
  /* display: grid; */
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.image_gridSet {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
  margin-top: 20px;
}

.planImg {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  width: 100%;
  height: 100%;
  cursor: pointer;
  max-width: 210px;
  max-height: 280px;
}

.imageItem {
  width: 100%;
  height: 100%;
  object-fit: cover;
  max-width: 210px;
  max-height: 280px;
}

.lockIcon {
  display: block;
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 2;
}

.lockIcon img {
  width: 30px;
  height: 30px;
}

@media only screen and (max-width: 400px) {
  .image_gridSet {
    display: grid;
    grid-template-columns: repeat(2, 1fr) !important;
    grid-gap: 20px;
    margin-top: 20px;
  }
}

@media only screen and (max-width: 479px) {
  .image_grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
    margin-top: 37px;
  }

  .image_middle {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
    display: grid;
    justify-content: center;
  }

  .planTopSection {
    padding-right: 10px;
    padding-left: 10px;
  }

  .image_gridSet {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
    margin-top: 20px;
  }

  .planImage {
    max-width: unset !important;
    max-height: unset !important;
  }

  .imageItemSet {
    max-width: unset !important;
    max-height: unset !important;
  }
}

@media only screen and (min-width: 480px) and (max-width: 575px) {
  .image_grid {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
  }

  .image_middle {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
    display: grid;
  }

  .planTopSection {
    padding-right: 10px;
    padding-left: 10px;
  }

  .image_gridSet {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
    margin-top: 20px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .image_grid {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
  }

  .image_middle {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
    display: grid;
  }

  .image_gridSet {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
    margin-top: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .image_grid {
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
  }

  .image_middle {
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
    display: flex;
    justify-content: center;
  }

  .image_gridSet {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
    margin-top: 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .image_grid {
    grid-gap: 20px;
  }

  .image_middle {
    grid-gap: 20px;
    display: flex;
    justify-content: center;
  }
}

.nameOnImage {
  position: absolute;
  bottom: 0px;
  width: 100%;
  display: flex;
  height: 80px;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(transparent, black 90%);
}

.nameOnImage h6 {
  color: #fff;
  text-align: center;
  margin-bottom: 20px;
  padding: 0px 7px;
  font-weight: 800;
}

/* fitnessplandetails section css  */
.planDetailSection {
  padding-top: 20px;
  padding-bottom: 40px;
}

.planDetailImage {
  width: 100%;
  height: 100%;
  max-width: 400px;
  max-height: 533.33px;
  position: relative;
}

.planDetailImage img {
  width: 100%;
  height: 100%;
  max-width: 400px;
  max-height: 533.33px;
}

.titleSet {
  position: absolute;
  bottom: 0px;
  width: 100%;
  display: flex;
  height: 147px;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(transparent, black 90%);
}

.titleSet h2 {
  color: #fff;
  text-align: center;
  margin-bottom: 20px;
  padding: 0px 10px;
  font-weight: 800;
  font-size: 30px;
  text-transform: uppercase;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.planDetailSection h1 {
  font-weight: 800;
  font-size: 30px;
  color: #111;
  margin-top: 0px;
  text-align: center;
  line-height: 40px;
  margin-bottom: 35px;
}

.detailsOptions {
  margin-top: 20px;
  /* border-bottom: 1px solid #3e3e3e; */
  padding-bottom: 20px;
}

.endOption {
  border-bottom: none !important;
}

.borderSet {
  /* border-bottom: none !important; */
  border-bottom: 1px solid #3e3e3e;
}

.detailsOptions h2 {
  font-weight: 800;
  font-size: 25px;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
}

.detailsOptions p {
  font-weight: 300;
  font-size: 15px;
  line-height: 2 !important;
  color: #828282;
  text-align: center;
  margin-bottom: 0px !important;
}

.endOption p {
  text-align: left !important;
}

.planBuyBtn {
  text-align: center;
  margin-top: 10px;
}

.planBuyBtn button {
  width: 100%;
  height: 45px;
  max-width: 400px;
  font-size: 15px;
  font-weight: 500;
  color: #fff;
  background: #333;
  border: 1px solid #333 !important;
  border-radius: 150px;
}

@media only screen and (max-width: 767px) {
  .planDetailSection h1 {
    font-size: 26px;
  }

  .detailsOptions h2 {
    font-size: 23px;
  }
}

@media only screen and (max-width: 991px) {
  .planDetailRight {
    margin-top: 40px;
  }
}

@media only screen and (max-width: 575px) {
  .planDetailRight {
    margin-left: 10px;
    margin-right: 10px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .planDetailRight {
    margin-left: 15px;
    margin-right: 15px;
  }
}

@media only screen and (min-width: 1200px) {
  .planDetailRight {
    margin-left: 20px;
    margin-right: 20px;
  }
}

@media only screen and (min-width: 400px) and (max-width: 479px) {
  .planDetailImage {
    width: 100%;
    height: 100%;
    margin: 0px auto;
    max-width: unset;
    max-height: unset;
  }

  .planDetailImage img {
    width: 100%;
    height: 100%;
    max-width: unset;
    max-height: unset;
  }

}

@media only screen and (min-width: 480px) and (max-width: 575px) {
  .planDetailImage {
    width: 100%;
    height: 100%;
    margin: 0px auto;
    max-width: unset;
    max-height: unset;
  }

  .planDetailImage img {
    width: 100%;
    height: 100%;
    max-width: unset;
    max-height: unset;
  }

  .planDetailRight {
    margin-left: 20px;
    margin-right: 20px;
  }

}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .planDetailImage {
    width: 100%;
    height: 100%;
    margin: 0px auto;
    max-width: unset;
    max-height: unset;
  }

  .planDetailImage img {
    width: 100%;
    height: 100%;
    max-width: unset;
    max-height: unset;
  }

  .planDetailRight {
    margin-left: 20px;
    margin-right: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .planDetailImage {
    width: 100%;
    height: 100%;
    margin: 0px auto;
    max-width: unset;
    max-height: unset;
  }

  .planDetailImage img {
    width: 100%;
    height: 100%;
    max-width: unset;
    max-height: unset;
  }

  .planDetailSection h1 {
    margin-top: 40px;
  }
}

/* plan modal popup css start */
.modalHeader svg {
  cursor: pointer;
  margin-right: 15px;
}

.modalHeading {
  margin: 0px auto;
}

.modalHeading h4 {
  font-size: 24px;
  text-align: center;
  text-transform: uppercase;
  font-weight: 800;
  font-family: "ArcaMajora3-Heavy";
  margin: 0;
  line-height: 1.42857143;
}

.modalBody {
  padding: 20px 50px;
  border-bottom: 1px solid #e5e5e5;
}

.modalBodyText {
  display: flex;
  padding: 0 0 10px;
}

.modalBodyText h5 {
  padding-right: 10px;
  color: #111111;
  font-size: 16px;
  width: 170px;
}

.modalBodyText h6 {
  font-size: 16px;
  color: #828282;
  width: 170px;
}

.modalPriceText {
  text-align: center;
  padding: 15px 50px;
}

.modalPriceText h1 {
  color: #111;
  margin-top: 0;
  font-size: 32px;
  margin-bottom: 0;
  text-transform: uppercase;
  font-weight: 800;
}

.modalPriceText h3 {
  font-size: 28px;
  margin-top: 10px;
  margin-bottom: 0;
  color: #111;
  font-weight: 800;
  text-transform: uppercase;
}

.modalFooter {
  text-align: center;
  padding: 0 50px;
}

.modalFooter h4 {
  margin-top: 0;
  font-size: 16px;
  color: #616161;
  text-align: center;
}

.modalFooter p {
  font-size: 13px;
  color: #828282;
  line-height: 20px;
  text-align: center;
}

.modalFooter p b {
  color: #111;
  font-weight: 500;
}

.payPalButton {
  width: 100%;
  max-width: 300px;
  margin: 0px auto;
  text-align: center;
}

.payPalImage img {
  width: 100%;
  max-width: 180px;
  margin-top: 5px;
}

@media only screen and (max-width: 500px) {
  .modalBody {
    padding: 20px;
  }

  .modalBodyText h6 {
    word-break: break-word;
  }
}

@media only screen and (max-width: 400px) {
  .modalBodyText h5 {
    width: 100px;
  }
}

.widthManages {
  width: 100%;
  max-width: 824px;
  margin: 0px auto;
}

.lockIcon svg {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background: #7dbec1;
  color: #fff;
  padding: 5px;
}

.lockIconDetail {
  display: block;
  position: absolute;
  top: 9px;
  right: 9px;
  z-index: 2;
  background: #7dbec1;
  border-radius: 150px;
  padding: 5px 18px;
}

.lockIconDetail img {
  width: 30px;
  height: 30px;
}

.lockIconDetail svg {
  color: #fff;
  margin-bottom: 3px;
}

.lockIconDetail span {
  color: #fff;
}

.lockIconDetail p {
  color: #fff;
  margin-bottom: 0px;
  font-weight: 500;
  font-size: 14px;
}

.planImage {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  width: 100%;
  height: 100%;
  cursor: pointer;
  max-width: 170px;
  max-height: 226px;
}

.imageItemSet {
  width: 100%;
  height: 100%;
  object-fit: cover;
  max-width: 170px;
  max-height: 226px;
}

@media only screen and (min-width: 1400px) {
  .planImage {
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    width: 100%;
    height: 100%;
    cursor: pointer;
    max-width: 190px;
    max-height: 253px;
  }

  .imageItemSet {
    width: 100%;
    height: 100%;
    object-fit: cover;
    max-width: 190px;
    max-height: 253px;
  }
}

.logoutBtn {
  padding-top: 10px;
}

.logoutBtn button {
  font-size: 15px;
  font-weight: 400;
  text-decoration: none;
  color: #fff !important;
  border-radius: 50px;
  padding: 8px 20px;
  background-color: #4aa82b;
  border: 1px solid #4aa82b;
  display: inline-block;
}

.subscriptionPart {
  text-align: center;
}

.subscriptionPart h2 {
  color: #fff;
  font-size: 15px;
  font-weight: 500;
}

.purchaseButton {
  text-align: center;
  margin-top: 10px;
}

.purchaseButton button {
  width: 100%;
  height: 45px;
  max-width: 400px;
  font-size: 15px;
  font-weight: 500;
  color: #fff;
  background: #333;
  border: 1px solid #333 !important;
  border-radius: 150px;
  cursor: unset !important;
}
.subHeading h2 {
  text-align: center;
  color: #ffffff;
  margin-top: 20px;
  font-size: 30px;
  font-weight: 800;
}

.summaryText h2 {
  color: #c3c5c7;
  font-size: 20px;
  text-align: left;
  border-bottom: 1px solid #3e3e3e;
  padding: 25px 0px;
  font-weight: 700;
}

.summaryTexts h2 {
  color: #fff;
  font-size: 22px;
  text-align: center;
  border-bottom: 1px solid #3e3e3e;
  padding: 25px 0px;
}

.subscriptionPayBox {
  background-color: #303030;
  margin: 10px auto;
  padding: 10px 25px;
  width: 100%;
  max-width: 600px;
  border-radius: 15px;
}

.payText {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #3e3e3e;
  padding: 15px 0px;
}

.payText p {
  font-size: 15px;
  color: #fff;
  /* text-transform: capitalize; */
  margin-bottom: 0px;
}

.payText p span {
  font-weight: 700 !important;
}

.payTexts {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #3e3e3e;
  padding: 15px 5px;
  gap: 10px;
}

.payTexts p {
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  text-transform: capitalize;
}

.timeText {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #3e3e3e;
  padding: 5px 0px;
}

.timeText p {
  font-size: 15px;
  color: #fff;
  text-transform: capitalize;
  margin: 10px 0px;
}

.timeTexts {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #3e3e3e;
  padding: 15px 5px;
}

.timeTexts p {
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  text-transform: capitalize;
  margin: 10px 0px;
}

.priceText {
  display: flex;
  justify-content: space-between;
  padding: 15px 0px 13px;
}

.priceText p {
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  text-transform: capitalize;
  margin-bottom: 0px;
}

.priceTexts {
  display: flex;
  justify-content: space-between;
  padding: 15px 5px 13px;
}

.priceTexts p {
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  text-transform: capitalize;
}

.backButton {
  display: flex;
  justify-content: center;
  margin-top: -20px;
}

.backButton button {
  border-radius: 150px;
  padding: 8px 12px;
  font-size: 15px;
  margin-bottom: 6px;
  font-weight: 600;
  color: #fff;
  background-color: #333;
  height: 45px;
  border: 1px solid #fff;
  margin-top: 10px;
}

.backButton button svg {
  font-size: 20px;
  margin-bottom: 6px;
  font-weight: 800;
  align-items: center;
  color: #fff;
  text-align: center;
  margin-right: 6px;
  margin-top: 4px;
}

.subscriptionDetailText {
  width: 100%;
  margin: 25px auto;
  max-width: 824px;
}

.subscriptionDetailText p {
  color: #c5c6c9;
  font-size: 15px;
  font-weight: 300;
  line-height: 1.8;
  text-align: left;
}

.subscriptionDetailText span {
  color: #c5c6c9;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.8;
  text-align: left;
}

.payPalImg {
  text-align: center;
  margin-top: -24px;
}

.payPalImg img {
  width: 100%;
  max-width: 209px;
}

.payPal {
  margin-top: 10px;
  width: 100%;
  max-width: 211px;
}

.poweredImg {
  display: flex;
  justify-content: center;
  margin-top: -23px;
}

.poweredImg img {
  width: 100%;
  max-width: 75px;
  margin-left: 5px;
}

.poweredImg h4 {
  color: #1b9dd1;
  font-size: 16px;
  text-transform: uppercase;
  font-style: italic;
  font-weight: 600;
}

.dateShow {
  border: 1px solid #3e3e3e;
  padding: 10px 5px;
  border-radius: 150px;
  text-align: center;
  margin-top: 10px;
  width: 200px;
}

@media only screen and (min-width: 310px) and (max-width: 360px) {
  .subHeading h2 {
    font-size: 26px;
  }

  .payPal {
    margin-top: 10px;
    width: 100%;
    max-width: 185px;
  }

  .subscriptionDetailText p {
    margin: 10px 12px;
  }

  .subscriptionPayBox {
    padding: 10px 5px;
  }

  .payPalImg img {
    width: 100%;
    max-width: 186px;
  }

  .poweredImg h4 {
    font-size: 14px !important;
  }

  .poweredImg {
    margin-top: -17px !important;
  }

  .payTexts p {
    font-size: 12px !important;
  }

  .timeTexts p {
    font-size: 12px !important;
  }


  .priceTexts p {
    font-size: 12px !important;
  }
}

@media only screen and (min-width: 361px) and (max-width: 415px) {
  .subHeading h2 {
    font-size: 26px;
  }

  .payPal {
    margin-top: 10px;
    width: 100%;
    max-width: 190px;
  }

  .subscriptionDetailText p {
    margin: -15px 12px;
  }

  .poweredImg {
    display: flex;
    justify-content: center;
    margin-top: -19px !important;
  }

  .payPalImg img {
    width: 100%;
    max-width: 190px;
  }

  .poweredImg h4 {
    font-size: 14px !important;
  }

  .payTexts p {
    font-size: 12px !important;
  }

  .timeTexts p {
    font-size: 12px !important;
  }


  .priceTexts p {
    font-size: 12px !important;
  }

  .subscriptionPayBox {
    padding: 10px 5px;
  }
}


@media only screen and (max-width: 500px) {
  .payTexts p {
    font-size: 14px;
  }

  .timeTexts p {
    font-size: 14px;
  }


  .priceTexts p {
    font-size: 14px;
  }

  .summaryTexts h2 {
    font-size: 20px;
  }
}
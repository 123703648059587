/* headersection background image css */
.headerSection {
  height: 100vh;
  position: relative;
  overflow: hidden;
  padding: 0;
}

.headerSection::after {
  content: "";
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
}

.headingText h2 {
  display: flex;
  justify-content: center;
  color: #ffffff;
  font-size: 30px;
  font-weight: 800;
  line-height: 40px;
}

.headingText {
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 110px;
  z-index: 1;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.headingText p {
  color: #c5c6c9;
  font-weight: 300;
  font-size: 15px;
  line-height: 2;
  margin: 0 auto 25px;
  max-width: 540px;
}

.loginBtn {
  display: flex;
  justify-content: center;
}

.StartUp a {
  font-size: 15px;
  font-weight: 400;
  text-decoration: none;
  color: #ffffff !important;
  border-radius: 50px;
  padding: 8px 33px;
  margin-top: 5px;
  border: 1px solid #f2f2f28f;
  line-height: 1.8;
  margin-right: 10px;
}

.StartUp a:hover {
  color: #333 !important;
  background: #ffffff !important;
  border-color: #ffffff;
}

.StartUp1 a {
  font-size: 15px;
  font-weight: 400;
  text-decoration: none;
  color: #ffffff !important;
  border-radius: 50px;
  padding: 8px 25px;
  margin-top: 5px;
  border: 1px solid #f2f2f28f;
  line-height: 1.8;
}

.StartUp1 a:hover {
  color: #333 !important;
  background: #ffffff !important;
  border-color: #ffffff;
}

@media only screen and (min-width: 310px) and (max-width: 767px) {
  .headerSection::after {
    content: "";
    background: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100vh;
    left: 0;
    top: 0;
    position: absolute;
  }

  .borderBottom {
    border-bottom: 1px solid #424141;
    width: 100%;
    max-width: 260px !important;
    margin: 0 auto;
  }
}

@media only screen and (max-width: 767px) {
  .headerSection {
    padding: 5px 0 !important;
  }
}

@media only screen and (max-width: 1199px) {
  .headerSection .backgroundImage {
    width: auto;
    height: 100%;
  }
}

@media only screen and (min-width: 767px) and (max-width: 991px) {
  .headerSection .backgroundImage {
    width: auto;
    height: 100%;
    background-position: center;
  }
}

@media only screen and (max-width: 991px) {
  .headerSection {
    background-position: center;
  }
}

@media only screen and (max-width: 767px) {
  .headingText {
    top: 72%;
    bottom: auto;
    transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    padding: 0 15px;
  }
}

@media only screen and (max-width: 479px) {
  .headingText h2 {
    font-size: 26px;
    line-height: 1.8;
    margin-bottom: 5px;
  }

  .headingText p {
    font-size: 15px;
    line-height: 25px;
    font-weight: 300;
  }
}

@media only screen and (min-width: 480px) and (max-width: 575px) {
  .headingText h2 {
    font-size: 26px;
    line-height: 1.8;
  }

  .headingText p {
    font-size: 20px;
    line-height: 1.8;
  }
}

.textSection {
  text-align: center;
  margin-top: 20px;
}

.headSection {
  font-weight: 800;
  font-size: 30px;
  color: #fff;
  margin-top: 9px;
  text-align: center;
  line-height: 40px;
  margin-bottom: 6px;
}

.textSection p {
  font-weight: 300;
  font-size: 15px;
  line-height: 2 !important;
  color: #c5c6c9;
  text-align: left;
  margin-bottom: -5px;
}

.middileSection {
  max-width: 824px;
  margin: 0px auto;
  width: 100%;
}

.socialSection {
  text-align: center;
  margin-top: 14px;
  margin-bottom: 0px;
}

.socialSection img {
  width: 100%;
  max-width: 161px;
  margin-left: 10px;
}

.trainersImg {
  margin-top: 20px;
}

.trainersImg img {
  width: 100%;
  object-fit: cover;
}

.trainersImg h2 {
  font-weight: 500;
  font-size: 14px;
  color: #000000;
  text-align: center;
  margin: 12px 0px 25px;
}

.bottompara p {
  font-weight: 300;
  font-size: 15px;
  line-height: 2 !important;
  color: #828282;
  /* padding-bottom: 15px; */
  display: flex;
  align-items: center;
}

.trainingImg {
  text-align: center;
  /* margin: 25px 0px 0px; */
  overflow: hidden;
}

.trainingImg img {
  object-fit: cover;
  height: 100%;
  width: 100% !important;
  max-width: 900px;
}

.trainerList {
  padding: 0px 3px 0px 3px;
}

.ratingHeading {
  margin-left: 10px;
  margin-top: 20px;
}
.appStoreRating {
  margin: 30px auto;
  width: 100%;
  max-width: 358px;
}

.AppleftImg img {
  width: 100%;
  max-width: 110px;
  background: #000;
  padding: 5px 5px 5px 5px;
  border-radius: 8px;
}

.starIcon {
  margin-left: 8px;
}

.starIcon svg {
  color: #ffb21d;
  font-size: 20px;
  /* margin-left: 3px; */
  margin-left: 5px;
  vertical-align: sub;
}
.appleText {
  margin-left: 12px;
}
.appleText p {
  font-size: 18px;
  margin-top: 6px;
  margin-bottom: 0px;
  color: #666;
  font-weight: 600;
}

.appleText span {
  font-size: 18px;
  color: #666;
  font-weight: 600;
}
.doviesHeading h2 {
  font-size: 22px;
  margin-top: 16px;
  font-weight: 600;
  margin-bottom: 15px;
}

.reviewSection {
  display: flex;
  text-align: -webkit-right;
  justify-content: center;
  /* margin-bottom: -17px; */
}
.ratingHeading h2 {
  color: #ffffff;
  /* font-size: 20px; */
  font-size: 27px;
  font-weight: 800;
}

.appStoreLink {
  display: flex;
}

.bottompara {
  max-width: 824px;
  width: 100%;
  margin: 0px auto;
  padding-top: 2px;
  /* border-bottom: 1px solid #424141; */
}

.bottompara p {
  color: #c5c6c9;
  font-size: 15px;
  font-weight: 300;
  margin-top: -15px;
}

.signupfree {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  margin-top: 20px;
}

.signupfree .btnBanner1 {
  border: 1px solid #3e3e3e !important;
  color: #fff;
  border: 0;
  padding: 12px 35px;
  display: inline-block;
  font-size: 12px;
  /* text-transform: capitalize; */
  transition: color 0.3s, background-color 0.3s;
  border-radius: 150px;
  font-weight: 600;
  text-decoration: none;
}

.signupfree .btnBanner1:hover {
  background: #fff;
  color: #333;
  border-color: #fff !important;
}

.starIcon p {
  color: #c5c6c9;
  font-weight: 400 !important;
  font-size: 14px !important;
  margin: 0px 0px 0px 0px;
}

.starIcon p span {
  margin-left: 5px;
}

.borderBottom {
  border-bottom: 1px solid #3e3e3e;
  width: 100%;
  max-width: 550px;
  margin: 0 auto;
  margin-top: 25px;
  margin-bottom: 25px;
}

.imgslider p {
  color: #c5c6c9;
  display: flex;
  justify-content: center;
  font-size: 15px;
  font-weight: 500;
  margin-top: 15px;
}

.imgslider {
  width: 100%;
  max-width: 834px;
  margin: 20px auto;
}

@media only screen and (max-width: 767px) {
  .headSection {
    font-size: 26px;
  }
}

@media only screen and (max-width: 479px) {
  .textSection p {
    font-size: 15px;
    line-height: 1.8;
    letter-spacing: -0.022em !important;
    margin: 0px 14px 0px 14px !important;
  }
}

@media only screen and (min-width: 480px) and (max-width: 575px) {
  .headSection {
    font-size: 26px;
  }

  .textSection p {
    font-size: 15px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 575px) {
  .bottompara p {
    font-size: 15px;
    line-height: 1.8 !important;
    margin: 0px 14px 0px 14px !important;
    letter-spacing: -0.022em !important;
    text-align: left;
  }

  .trainersImg h2 {
    color: #000;
    font-size: 14px;
    text-align: center;
    font-weight: 500 !important;
  }

  .ratingHeading {
    margin-left: 19px;
    margin-top: 5px;
    margin-right: 11px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .trainersImg h2 {
    font-size: 19px;
    line-height: 1.8;
  }
}

@media only screen and (max-width: 330px) {
  .ratingHeading h2 {
    color: #ffffff;
    font-size: 22px;
  }
}

@media only screen and (max-width: 767px) {
  .borderBottom {
    margin-top: 20px;
  }

  .imgslider {
    width: 100%;
    margin: 20px auto;
  }

  .homefitnessSlider .slick-slide img {
    width: 100% !important;
    height: 100%;
  }

  .starIcon p {
    /* margin: 0px -11px 0px 13px; */
    margin: 0px -7px 0px 15px;
  }

  .starIcon p {
    font-size: 14px !important;
    margin: 0px 0px 0px 0px;
  }
  .starIcon p span {
    margin-left: 5px !important;
  }

  .starIcon svg {
    font-size: 20px;
    margin-left: 5px;
    vertical-align: sub;
  }

  .ratingHeading h2 {
    color: #ffffff;
    font-size: 27px;
  }

  .ratingHeading {
    /* margin-left: 9px; */
    margin-left: 19px;
    margin-top: 17px;
  }

  /* .AppleftImg {
    margin-left: 12px;
  } */

  .AppleftImg img {
    width: 100%;
    max-width: 95px !important;
    background: #000;
    padding: 5px 5px 5px 5px;
    border-radius: 8px;
  }

  .socialSection img {
    width: 100%;
    max-width: 155px;
    /* margin-right: 12px; */
    margin-left: 10px;
  }
  .starIcon {
    margin-left: 0px;
  }

  .appStoreRating {
    margin: 20px auto;
    width: 100%;
    max-width: 358px;
  }
}

@media only screen and (max-width: 345px) {
  .socialSection img {
    width: 100%;
    max-width: 130px !important;
    /* margin-right: 12px; */
    margin-left: 10px;
  }

  .ratingHeading {
    margin: 10px 10px 0px 11px !important;
  }
  .starIcon p {
    margin: 0px 1px 0px 0px !important;
    font-size: 12px !important;
  }
}

.appHeader {
  margin-top: -5px;
}

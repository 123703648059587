.SocialLoginBtn {
    text-align: center;
    margin-top: 40px;
}

.SocialLoginBtn button {
    color: #FFFFFF;
    background-color: #333333;
    border-color: #333333;
    height: 40px;
    width: 100%;
    position: relative;
    text-align: center;
    border: 1px solid transparent;
    border-radius: 5px;
    max-width: 240px;
}

.SocialLoginBtn button img {
    width: 24px;
    height: 22px;
    position: absolute;
    left: 17px;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
}

.SocialLoginBtn button p {
    font-size: 15px;
    white-space: normal;
    margin: 6px 0px 6px;
    color: #FFFFFF;
    font-weight: 400;
    margin-left: 20px;
}
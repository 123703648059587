.contentText {
  max-width: 100%;
  padding: 10px;
  font-family: "Roboto", sans-serif !important;
  font-size: 14px;
}

.contentText h2 {
  text-align: center;
  font-size: 24px;
  font-weight: bolder;
  margin-top: 20px;
  margin-bottom: 25px;
  color: #fff;
}





.SignUpWrapper {
  background: #fff;
  padding: 20px 0;
  /* margin-top: 40px; */
}

.FormBlock {
  max-width: 450px;
  margin: 0 auto;
}

.FormSection {
  padding: 15px 50px;
}

.SignupTitle {
  text-align: center;
  margin-bottom: 20px;
}

.SignupTitle img {
  width: 100%;
  max-width: 100px;
}

.SignupTitle h2 {
  font-size: 26px;
  font-weight: 700;
  text-transform: uppercase;
  color: #fff;
  line-height: 40px;
}

.Line {
  display: table;
  white-space: nowrap;
  padding: 15px 0;
}

.Line:before,
.Line:after {
  background: linear-gradient(to bottom, #c7c7c71c, #efefef85) no-repeat center /
    98% 2px;
  content: "";
  display: table-cell;
  width: 50%;
}

.LineContent {
  padding: 0 15px;
  line-height: 1.6;
  font-size: 15px;
  color: #fff;
  font-weight: 500;
}

.FormGroup {
  margin-bottom: 22px;
}

.FormSection .FormGroup .FormInput,
.FormSection .FormGroup select {
  padding: 0px 10px;
  height: 45px;
  font-size: 15px;
  width: 100%;
  border-radius: 4px;
  background-color: #333 !important;
  color: #f0f8ffc4;
  font-weight: 300;
  line-height: 1.6 !important;
  touch-action: manipulation;
}

.FormSection .FormGroup .FormInput,
.FormSection .FormGroup select:focus {
  outline: none;
  border: none;
  box-shadow: none;
}

.FormGroupCheck {
  margin-top: 20px;
}

.FormGroupCheck label {
  padding-left: 4px !important;
  cursor: pointer !important;
  font-size: 15px !important;
  font-weight: 300 !important;
  color: #fff;
}

.Terms p {
  text-align: center;
  color: #c7c7c6;
  font-size: 15px;
  font-weight: 300;
  line-height: 1.6;
  text-align: left;
}

.Terms p a,
.Terms p a:hover {
  color: #fff;
  font-size: 15px;
  font-weight: 400;
}

.signUpBtn {
  margin-bottom: 10px;
  margin-top: 20px;
  text-align: center;
}

.signUpBtn button {
  width: 100%;
  height: 45px;
  font-size: 15px;
  font-weight: 500;
  color: #fff;
  background: #333;
  border: 1px solid #333 !important;
  border-radius: 150px;
  max-width: 290px;
}

.signUpBtn button a {
  font-size: 15px;
  font-weight: 400;
  color: #fff !important;
  text-decoration: none;
  background-color: #333;
}

.AccountSection p {
  text-align: center;
  margin-bottom: 0;
  font-size: 15px;
  color: #c7c7c6;
  font-weight: 400;
}

.AccountSection p a {
  color: #fff;
  font-weight: 400;
  font-size: 15px;
  text-decoration: none;
}

.GetApp {
  text-align: center;
  margin-top: 20px;
}

.GetApp p {
  margin-bottom: 20px;
  font-size: 15px;
  letter-spacing: 0.6px;
  color: #fff;
  line-height: 24px;
  font-weight: 400;
}

.AppLink a {
  display: inline-block;
  overflow: hidden;
  border-radius: 6px;
  margin-right: 6px;
  margin-bottom: 6px;
}

.AppLink a img {
  width: 100%;
  max-width: 130px;
}

.FormGroup .DobWrapper {
  display: flex;
}

.FormGroup .DobField {
  position: relative;
  width: 33.33%;
  padding: 0 5px;
}

.FormGroup .DobField:first-child {
  padding-left: 0;
}

.FormGroup .DobField:last-child {
  padding-right: 0;
}

.FormGroup .DobField svg {
  position: absolute;
  top: 12px;
  right: 8px;
  font-size: 20px;
  color: aliceblue;
}

.SocialLoginBtn button {
  color: #fff;
  background-color: #3a5999;
  border-color: #3a5999;
  min-height: 37px;
  width: 100%;
  position: relative;
  padding: 2px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.42857143;
  text-align: center;
  border: 1px solid transparent;
  border-radius: 4px;
}

.SocialLoginBtn button img {
  width: 26px;
  height: 26px;
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
}

.SocialLoginBtn button p {
  font-size: 15px;
  white-space: normal;
  margin: 6px 30px 6px;
  color: #fff;
  font-weight: 300;
}

.resetPassword {
  background-color: #f5f5f5;
  padding: 90px 0px;
}

.signUpBtn a {
  font-size: 15px;
  font-weight: 500;
  text-align: center;
  color: #fff !important;
  text-decoration: none;
  display: flex;
  justify-content: center;
  padding-top: 8px;
}

@media only screen and (max-width: 479px) {
  .SignupTitle img {
    width: 100%;
    max-width: 100px;
  }

  .FormSection {
    padding: 7px 8px !important;
  }

  .SocialLoginBtn button p {
    font-size: 12px;
  }
}

/* new css */
.FormSection .FormGroup .FormInput,
.FormSection .FormGroup ::placeholder {
  color: #f0f8ffc4 !important;
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .SignupTitle h2 {
    font-size: 20px !important;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 30px;
  }
}

@media screen and (max-width: 479px) {
  .loginAccountSection p {
    font-size: 13px !important;
  }
  .loginAccountSection p a {
    font-size: 13px !important;
  }
  .loginAccountSection p a {
    font-size: 13px !important;
  }
}

/* new login css */
.loginPara p {
  color: #ffffff;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1px;
  margin-bottom: 22px;
  line-height: 22px;
}

.loginAccountSection {
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
}

.loginAccountSection p {
  text-align: center;
  margin-bottom: 0;
  font-size: 15px;
  color: #c7c7c6;
  font-weight: 400;
}

.loginAccountSection p a {
  color: #fff;
  font-weight: 400;
  font-size: 15px;
  text-decoration: none;
}

.loginAccountSection .joinText a {
  text-decoration: underline;
}

.loginBtn {
  margin-bottom: 10px;
  margin-top: 20px;
  text-align: center;
}

.loginBtn button {
  width: 100%;
  max-width: 290px;
  border-radius: 150px;
  height: 45px;
  font-size: 15px;
  font-weight: 500;
  color: #fff;
  background: #333;
  border: 1px solid #333 !important;
}

.loginBtn button a {
  font-size: 15px;
  font-weight: 400;
  color: #fff !important;
  text-decoration: none;
}

.loginTerms {
  margin-top: 15px;
  margin-bottom: 25px;
}

.loginTerms p {
  text-align: center;
  color: #c7c7c6;
  font-size: 15px;
  font-weight: 300;
  line-height: 1.6;
  text-align: left;
}

.loginTerms p a,
.loginTerms p a:hover {
  color: #ffffff;
  font-size: 15px;
  font-weight: 400;
}

.loginLine {
  display: flex;
  justify-content: center;
  white-space: nowrap;
  padding: 20px 0;
}

.loginLine .LineContent {
  padding: 0 7px;
  line-height: 1.6;
  font-size: 15px;
  color: #fff;
  font-weight: 400;
}

.loginLine:before,
.loginLine:after {
  background: linear-gradient(to bottom, #d9d9d9, #d9d9d9) no-repeat center /
    98% 1px;
  content: "";
  display: table-cell;
  width: 8%;
}

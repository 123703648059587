.faqText h3 {
  font-size: 26px;
  font-weight: 700;
  text-transform: uppercase;
  color: #fff;
  line-height: 60px;
  margin-top: 20px;
  text-align: center;
  margin-bottom: 21px;
}

.faqText{
  margin: 0px auto;
  width: 100%;
  max-width: 824px;
}
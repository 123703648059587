.headerSection .navBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  padding: 0px 0px;
}

@media only screen and (min-width: 991px) {
  .headerContent {
    margin: 0 auto;
    max-width: 824px;
    width: 100%;
  }
}

.headerSection {
  position: absolute;
  width: 100%;
  top: 5px;
  left: 0;
  z-index: 999;
  padding: 0px 0px 0;
  background: transparent;
}

.headerSection .logoImg img {
  width: 100%;
  max-width: 55px;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}
.headerSection .logoImgs img {
  width: 100%;
  max-width: 50px;
  border-radius: 150px;
  border: 1px solid #3e3e3e;
}

.headerSection .navBar .customNavLink {
  font-size: 15px;
  font-weight: 400;
  text-decoration: none;
  color: #fff;
  border-radius: 50px;
  padding: 8px 16px;
  border: 1px solid #f2f2f28f;
  margin-top: 0px;
  margin-left: 10px;
}

@media only screen and (min-width: 310px) and (max-width: 575px) {
  .headerSection .logoImg img {
    width: 100%;
    max-width: 50px;
    margin-top: 3px;
  }
  .headerSection .logoImgs img {
    width: 100%;
    max-width: 50px;
    margin-top: 3px;
  }

  .headerSection .navBar .customNavLink {
    font-size: 13px;
    font-weight: 400;
    text-decoration: none;
    color: #fff !important;
    border-radius: 50px;
    padding: 10px 16px !important;
    margin-top: -7px !important;
  }
}

.headerSection .navBar .customNavLink:hover {
  background-color: #fff;
  color: #000 !important;
}

.homeHeader {
  margin-top: -9px;
}

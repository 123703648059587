.topRight .nav-link .active {
  color: #ec3642 !important;
}

.profileSection {
  padding: 35px 0px;
}

.profileInfo {
  border: 1px solid #3e3e3e;
  border-radius: 5px;
  padding: 20px 15px;
  text-align: center;
  height: 384px;
}

.editIcon {
  text-align: right;
}

.editIcon svg {
  color: #fffcfc;
  font-size: 28px;
  font-weight: 500;
}

.profileImg {
  border: 1px solid #3e3e3e;
  border-radius: 50%;
  padding: 5px;
  display: inline-block;
}

.profileImg img {
  width: 140px;
  max-width: initial;
  height: 140px;
  border-radius: 50%;
  border: none;
  object-fit: cover;
}

.uploadPic {
  background: #333;
  border-radius: 44px;
  width: 35px;
  height: 35px;
  line-height: 35px;
  color: #fff;
  cursor: pointer;
}

.uploadPic svg {
  font-size: 20px;
  color: #fff;
  margin-left: 2px;
  margin-bottom: 2px;
}

.profileInput {
  bottom: 40px;
  margin: 0 auto;
  position: relative;
  left: 45px;
  text-align: center;
}

.nameHeding h2 {
  font-size: 15px;
  color: #fff;
  font-weight: 500;
  text-transform: capitalize;
}

.userName {
  font-size: 15px;
  color: #757575;
  line-height: 28px;
  font-weight: 300;
}

.logoutBtn {
  padding-top: 15px;
}

.logoutBtn button {
  font-size: 15px;
  font-weight: 500;
  text-decoration: none;
  color: #fff !important;
  border-radius: 50px;
  padding: 7px 30px;
  margin-top: 5px;
  border: 1px solid #333;
  line-height: 27px;
  background-color: #333;
}

.logoutBtn a {
  font-size: 15px;
  font-weight: 500;
  text-decoration: none;
  color: #fff !important;
  border-radius: 50px;
  padding: 7px 30px;
  border: 1px solid #2ea52ee8;
  line-height: 27px;
  background-color: #2ea52ee8;
}

.userInfo {
  border-bottom: 1px solid #3e3e3e;
  margin-bottom: 12px;
}

.formLabel {
  color: #fff;
  font-size: 15px;
  font-weight: 500;
}

.userInfo p {
  color: #757575;
  font-size: 15px;
  font-weight: 300;
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .topRight {
    padding-top: 25px;
  }
}
.contactBlock {
  padding: 30px 40px;
  max-width: 450px;
  margin: 0 auto;
}

.contactHeading h2 {
  font-size: 24px;
  font-weight: 800;
  text-transform: uppercase;
  color: #fff;
  line-height: 35px;
  text-align: center;
  margin-top: 0;
  margin-bottom: 10px;
}

.contactHeading p {
  font-size: 15px;
  font-weight: 300;
  letter-spacing: 0.6px;
  color: #c5c6c9;
  line-height: 2;
  text-align: left;
}

.formField .formGroup {
  margin-bottom: 15px;
}

.formField .formLabel {
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  padding-left: 3px;
  margin-bottom: 3px;
  font-family: "ArcaMajora3-Bold", sans-serif;
}

.formField .formLabel span {
  color: red;
  font-size: 20px;
  vertical-align: middle;
}

.formField .formGroup .contactFormInput,
.formField .formGroup select {
  border: none;
  padding: 0px 10px;
  height: 40px;
  font-size: 15px !important;
  width: 100%;
  border-radius: 4px;
  background-color: #333 !important;
  color: #f0f8ffc4 !important;
  font-weight: 300 !important;
  line-height: 1.6 !important;
}

.formField .formGroup .contactFormInput,
.formField .formGroup .contactFormInput::placeholder {
  color: #f0f8ffc4 !important;
}

.formField .formGroup .contactFormInput,
.formField .formGroup select:focus {
  outline: none !important;
  box-shadow: none !important;
  font-weight: 200;
  font-size: 15px !important;
}

.submitBtn {
  margin-bottom: 10px;
  margin-top: 20px;
}

.submitBtn button {
  width: 100%;
  height: 40px;
  font-size: 15px;
  font-weight: 400;
  color: #fff;
  background-color: #333;
  border: 1px solid #333 !important;
  border-radius: 5px;
}

.selectOption {
  position: relative;
}

.selectOption svg {
  position: absolute;
  color: #fff !important;
  top: 11px;
  right: 8px;
  font-size: 14px;
  font-weight: 600 !important;
}

.formField .formGroup .FormTextArea {
  border: 1px solid #333;
  padding: 0px 10px;
  height: 100px;
  font-size: 15px;
  width: 100%;
  border-radius: 4px;
  background: #333 !important;
  color: aliceblue !important;
  font-weight: 300 !important;
  line-height: 1.6 !important;
  font-family: poppins !important;
}

.formField .formGroup .FormTextArea:focus {
  outline: none !important;
  box-shadow: none !important;
}


